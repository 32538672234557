import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import RoleImage from '../../assets/images/role.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import { CheckCircleFilled, EditOutlined, PlusOutlined, LockOutlined } from '@ant-design/icons';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    form,
    ...restProps
}) => {
    let inputNode = <Input style={{ textAlign: 'center' }} />; // Center the text in the input field

    const getValue = (record, dataIndex) => {
        if (Array.isArray(dataIndex)) {
            return dataIndex.reduce((obj, key) => obj?.[key], record);
        }
        return record[dataIndex];
    };

    useEffect(() => {
        if (editing) {
            const value = getValue(record, dataIndex);
            form.setFieldsValue({
                rolename: value
            });
        }
    }, [editing]);

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name="rolename"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Please Input ${title}!` }]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Role = () => {
    const searchInput = useRef(null);
    const [roleList, setRoleList] = useState([]);
    const tableColumnSearch = columnSearch(searchInput);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const nameInputRef = useRef(null);
    
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.access.rolename === editingKey;

    useEffect(() => {
        fetchRoleList();
    }, []);

    const isAdminRole = (roleName) => roleName.toLowerCase() === 'admin';

    const edit = (record) => {
        // Don't allow editing if it's the Admin role
        if (isAdminRole(record.access.rolename)) {
            api.warning({
                message: 'Action Restricted',
                description: 'The Admin role cannot be modified.',
                duration: 2,
            });
            return;
        }

        form.setFieldsValue({
            rolename: record.access.rolename,
        });
        setEditingKey(record.access.rolename);
    };

    const cancel = () => {
        form.resetFields();
        setEditingKey('');
    };

    const update = async (originalRoleName) => {
        try {
            // Validate the form field
            const row = await form.validateFields();
            const newRoleName = row.rolename?.trim();
            
            // Basic validation
            if (!newRoleName) {
                api.error({
                    message: 'Validation Error',
                    description: 'Role name cannot be empty',
                    duration: 2,
                });
                return;
            }

            // Check if the new name is the same as the original
            if (originalRoleName === newRoleName) {
                setEditingKey('');
                form.resetFields();
                return;
            }

            // Check for duplicates (case-insensitive)
            const isDuplicate = roleList.some(
                (role) => 
                    role.access.rolename !== originalRoleName && 
                    role.access.rolename.toLowerCase() === newRoleName.toLowerCase()
            );

            if (isDuplicate) {
                api.error({
                    message: 'Duplicate Entry',
                    description: 'This role name already exists. Please enter a different name.',
                    duration: 2,
                });
                return;
            }

            setLoading(true);

            // Call the backend API to update the role
            const response = await httpCall({
                method: 'PUT',
                url: `/api/role/update/${encodeURIComponent(originalRoleName)}`,
                payload: { newRoleName },
            });

            if (response.data.success) {
                // Update the local state to reflect the change
                setRoleList(prevList => 
                    prevList.map(role => 
                        role.access.rolename === originalRoleName
                            ? { ...role, access: { ...role.access, rolename: newRoleName } }
                            : role
                    )
                );
                
                openNotification('Update Success!', 'Role Name Updated Successfully');
                form.resetFields();
                setEditingKey('');
            } else {
                throw new Error(response.data.message || 'Failed to update role name');
            }
        } catch (error) {
            console.error('Error updating role:', error);
            api.error({
                message: 'Update Failed',
                description: error.response?.data?.message || 'An error occurred while updating the role name.',
                duration: 2,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchRoleList = async () => {
        try {
            setLoading(true);
            const response = await httpCall({
                method: 'GET',
                url: '/api/role/get',
            });
            
            if (response.data.success) {
                const transformedData = response.data.data.map((item, index) => ({
                    key: index,
                    access: {
                        rolename: item.access.rolename
                    },
                    control: item.control
                }));
                setRoleList(transformedData);
            } else {
                throw new Error(response.data.message || 'Failed to fetch roles');
            }
        } catch (error) {
            console.error('Error retrieving role list:', error);
            api.error({
                message: 'Fetch Failed',
                description: error.message || 'Failed to retrieve role list',
                duration: 2,
            });
        } finally {
            setLoading(false);
        }
    };

    const openNotification = (msg, desc) => {
        api.open({
            message: msg,
            duration: 2,
            description: desc,
            icon: <CheckCircleFilled style={{ color: '#808000' }} />,          
        });
    };

    const handleSave = () => {
        const newRoleName = nameInputRef.current.input.value.trim();

        if (!newRoleName) {
            api.error({
                message: 'Validation Error',
                description: 'Role name cannot be empty',
                duration: 2,
            });
            return;
        }

        // Check for duplicates (case-insensitive)
        const isDuplicate = roleList.some(
            (role) => role.access.rolename.toLowerCase() === newRoleName.toLowerCase()
        );

        if (isDuplicate) {
            api.error({
                message: 'Duplicate Entry',
                description: 'This role name already exists. Please enter a different name.',
                duration: 2,
            });
            return;
        }

        httpCall({
            method: 'POST',
            url: '/api/role/add',
            payload: {
                roleName: newRoleName
            },
        })
        .then((response) => {
            if (response.data.success) {
                openNotification('Add Success!', 'New Role Added Successfully');
                nameInputRef.current.input.value = "";
                fetchRoleList();
            }
        })
        .catch((error) => {
            console.error('Error adding role:', error);
            api.error({
                message: 'Add Failed',
                description: error.response?.data?.message || 'Failed to add new role',
                duration: 2,
            });
        });
    };

    const handleTotal = (total, range) => (
        <div style={{fontFamily: 'Poppins', fontSize: '15px'}}>
            <strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records
        </div>
    );
    const columns = [
        {
            title: 'Role Name',
            dataIndex: ['access', 'rolename'],
            key: 'rolename',
            ...tableColumnSearch('rolename'),
            align: 'center',
            editable: true,
            width: 300,
            sorter: (a, b) => a.access.rolename.localeCompare(b.access.rolename),
            defaultSortOrder: 'ascend',
            render: (text, record) => (
                <Space>
                    {text}
                    {isAdminRole(text) && (
                        <LockOutlined style={{ color: '#808080' }} title="Admin role cannot be modified" />
                    )}
                </Space>
            )
        },      
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (_, record) => {
                const editable = isEditing(record);
                const isAdmin = isAdminRole(record.access.rolename);

                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => update(record.access.rolename)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space size="middle">
                        <Typography.Link 
                            disabled={editingKey !== '' || isAdmin}
                            onClick={() => edit(record)}
                        >
                            <EditOutlined
                                style={{
                                    cursor: (editingKey !== '' || isAdmin) ? 'not-allowed' : 'pointer',
                                    fontSize: '20px',
                                    color: (editingKey !== '' || isAdmin) ? '#d9d9d9' : '#08c',
                                }}
                            />
                        </Typography.Link>
                    </Space>
                );
            }     
        },
    ];
    
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: ['access', 'rolename'],
                title: col.title,
                editing: isEditing(record),
                form: form,
            }),
        };
    });

    const RoleAdd = () => (
        <div style={{ 
            marginTop: '2rem', 
            marginLeft: '2rem', 
            width: '100%', 
            display: 'flex', 
            gap: '20px', 
            justifyContent: 'start', 
            alignItems: 'center' 
        }}>
            <Space direction='vertical'>
                <span style={{fontSize: '13px'}}>Role Name</span>
                <Input
                    type="text"
                    placeholder='Enter Role Name'                           
                    style={{ width: '400px', marginBottom: '30px' }}
                    ref={nameInputRef}                           
                />
            </Space>
            <Button 
                type='primary' 
                style={{backgroundColor: 'green'}} 
                onClick={handleSave}
                icon={<PlusOutlined />}
            >
                Add Role
            </Button>
        </div>
    );

    return (
        <>
            <PageTitle
                imagesrc={RoleImage}
                pageTitle="Role List"
            />
            {contextHolder}
            <RoleAdd />
            <div className='roleList' style={{width: '550px', marginLeft:'30px'}}>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Form form={form} component={false}>
                        <Table 
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={roleList}
                            columns={mergedColumns}
                            rowKey={record => record.access.rolename}
                            rowClassName={record => 
                                `editable-row ${isAdminRole(record.access.rolename) ? 'admin-row' : ''}`
                            }
                            pagination={{
                                showSizeChanger: true, 
                                position: ['bottomCenter'], 
                                pageSizeOptions: ['10', '20', '30'], 
                                showTotal: handleTotal
                            }}
                            scroll={{ y: 500 }}
                            size='small'
                            style={{fontFamily: 'Poppins'}}
                        />
                    </Form>
                </Spin>
            </div>
        </>
    );
};

export default Role;