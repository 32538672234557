import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useLocation } from 'react-router-dom';

const PageUnderConstruction = () => {
    const location = useLocation();
	return (
		<Result
			icon={<SmileOutlined />}
			title="Page Under Development, Please Visit Later !"
			subTitle={`URL: ` + location.pathname}
            style={{marginTop: '15%'}}
		/>
	);
};

export default PageUnderConstruction;
