import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Select,
  Input,
  Radio,
  DatePicker,
  Button,
  notification,
} from "antd";
import { useFormik } from "formik";
import PageTitle from "../common/PageTitle";
import Imagesettlement from '../../assets/images/settlement.png';
import httpCall from "../../utils/api";
import moment from "moment";

const SettlementVendorCreate = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const formik = useFormik({
    initialValues: {
      purchase_no: "",
      purchase_no_inc: "",
      purchase_date: moment().format("YYYY-MM-DD"),
      vendor_id: "",
      rate_per_gram: 1,
      payment_mode_1: "Cash",
      account_1: "",
      amount_1_received: 0,
      payment_mode_2: "Cash",
      account_2: "",
      amount_2_received: 0,
      paid_weight: 0.0,
      vendor_paid_weight:"",
      purity:100, 
      pending_weight: 0.0,
      total_pending_weight: "",
      old_gold_weight: 0.0,
    },
    onSubmit: async (values) => {
      const paidAmount1 = Number(values.amount_1_received || 0);
      const paidAmount2 = Number(values.amount_2_received || 0);
      if (
        !values.vendor_id ||
        (paidAmount1 <= 0 && paidAmount2 <= 0) ||
        values.rate_per_gram <= 0
      ) {
        api.error({
          message: "Validation Error",
          description: "Please enter required fields.",
        });
        return;
      }

      try {
        await createVendorReceipt(values);
      } catch (error) {
        console.log("Failed to create invoice receipt");
      }
    },
  });

  const createVendorReceipt = async (values) => {
    try {
      const response = await httpCall({
        method: "POST",
        url: "/api/settlement/vendorSettlementReceipt",
        payload: values,
      });

      if (response) {
        navigate("/slj/settlementlist");
      } else {
        api.error({
          message: "Create Error",
          description: "Error while creating vendor receipt",
        });
      }
    } catch (error) {
      api.error({
        message: "Create Error",
        description: "Error while creating vendor receipt",
      });
      console.error("Error creating invoice receipt:", error);
    }
  };

  const fetchPaymentOptions = async () => {
    try {
      setLoading(true);
      const response = await httpCall({
        method: "GET",
        url: "/api/payment/list?filter=true",
      });
      setPaymentOptions(response?.data || []);
    } catch (error) {
      console.error("Error retrieving payment list:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNextVendorSettlementNo = async () => {
    try {
      const response = await httpCall({
        method: "GET",
        url: "/api/settlement/fetchNextVendorSettlementNo",
      });
      formik.setFieldValue(
        "purchase_no",
        `SV${response.data}/${moment().format("DDMMYYYY")}`
      );
      formik.setFieldValue("purchase_no_inc", response.data);
    } catch (error) {
      console.error("Error retrieving settlement receipt no:", error);
    }
  };

  const fetchVendorList = async () => {
    try {
      const response = await httpCall({
        method: "GET",
        url: "/api/vendor/get",
      });
      setVendorList(response?.data || []);
    } catch (error) {
      console.error("Error retrieving vendor list:", error);
    }
  };

  const fetchAccountList = async () => {
    try {
      setLoading(true);
      const response = await httpCall({
        method: "GET",
        url: "/api/account/list",
      });
      setAccountList(response?.data || []);
    } catch (error) {
      console.error("Error retrieving account list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendorList();
    fetchPaymentOptions();
    fetchAccountList();
    fetchNextVendorSettlementNo();
  }, []);

  const calculateWeights = (values) => {
    const rate = parseFloat(values.rate_per_gram || 0);
    const amount1 = parseFloat(values.amount_1_received || 0);
    const amount2 = parseFloat(values.amount_2_received || 0);
    const oldGold = parseFloat(values.old_gold_weight || 0);
    const paidAmount = amount1 + amount2;

    if (rate === 0) return { remainingWeight: 0, weightReceived: 0 };

    const remainingWeight = oldGold + paidAmount / rate;
    const weightReceived =
      values.total_pending_weight - remainingWeight >= 0
        ? parseFloat(values.total_pending_weight - remainingWeight).toFixed(3)
        : 0;

    return {
      remainingWeight:
        remainingWeight > 0 ? parseFloat(remainingWeight).toFixed(3) : 0,
      weightReceived,
    };
  };

  // useEffect
  useEffect(() => {
    const { remainingWeight, weightReceived } = calculateWeights(formik.values);
    formik.setFieldValue("paid_weight", remainingWeight);
    formik.setFieldValue("pending_weight", weightReceived);
  }, [
    formik.values.rate_per_gram,
    formik.values.amount_1_received,
    formik.values.amount_2_received,
    formik.values.old_gold_weight,
    formik.values.paid_weight,
  ]);

  useEffect(() => {
      if (formik.values.vendor_paid_weight !== '' && formik.values.purity !== '') {
        const vendor_paid_weight = parseFloat(formik.values.vendor_paid_weight || 0);
        const purity = parseFloat(formik.values.purity || 0);
        const old_gold_weight = vendor_paid_weight * (purity / 100);
        formik.setFieldValue('old_gold_weight', old_gold_weight.toFixed(3));
      }
    }, [formik.values.vendor_paid_weight, formik.values.purity]);

  return (
    <>
      <PageTitle
        imagesrc={Imagesettlement}
        pageTitle="Create Vendor Settlement"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/settlementlist",
          },
        ]}
      />
      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">
          <form onSubmit={formik.handleSubmit}>
            <Row gutter={16} justify="end">
              <Col span={5}>
                <span className="hintText color-black">Payment No</span>
                <Input
                  id="receipt_no"
                  disabled
                  style={{
                    width: "100%",
                    color: "#000",
                    fontSize: 14,
                  }}
                  value={formik.values.purchase_no}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <span className="hintText color-black">Vendor</span>
                <span className="mandatory"></span>
                <Select
                  id="vendor_id"
                  name="vendor_id"
                  placeholder="Select Vendor"
                  style={{ width: "100%", height: 38 }}
                  options={vendorList.map((vendor) => ({
                    label: vendor.company_name,
                    value: vendor.id,
                  }))}
                  value={formik.values.vendor_id}
                  onChange={(value) => {
                    const selectedVendor = vendorList.find(
                      (vendor) => vendor.id === value
                    );
                    formik.setFieldValue("vendor_id", value);
                    formik.setFieldValue(
                      "total_pending_weight",
                      selectedVendor?.pending_weight || 0
                    );
                  }}
                />
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Pending Weight (g)</span>
                <Input
                  id="total_pending_weight"
                  disabled
                  style={{ width: "100%", height: 38 }}
                  value={formik.values.total_pending_weight}
                />
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Payment Date</span>
                <DatePicker
                  style={{ width: "100%", height: 38 }}
                  value={moment(formik.values.payment_date)}
                  onChange={(date) =>
                    formik.setFieldValue("payment_date", date)
                  }
                  disabled
                />
              </Col>
            </Row>

            <Row gutter={16} justify="end">
              <Col span={8}>
                <span className="hintText color-black">Rate per gram</span>
                <span className="mandatory"></span>
                <Input
                  id="rate_per_gram"
                  name="rate_per_gram"
                  type="number"
                  style={{ width: "100%", height: 38 }}
                  // value={formik.values.rate_per_gram}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <span className="hintText color-black">Payment Mode 1</span>
                <Radio.Group
                  id="paymentMode1"
                  name="paymentMode1"
                  style={{ width: "100%", marginTop: 10 }}
                  value={formik.values.payment_mode_1}
                  onChange={(e) =>
                    formik.setFieldValue("payment_mode_1", e.target.value)
                  }
                >
                  {paymentOptions.map((option, index) => (
                    <Radio key={index} value={option.mop}>
                      {option.mop}
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Bank</span>
                <Select
                  id="bank1"
                  name="bank1"
                  placeholder="Select an Account"
                  style={{ width: "100%", height: 38 }}
                  disabled={formik.values.payment_mode_1 === "Cash"}
                  options={accountList.map((acc) => ({
                    label: `${acc.bank_name} - ${acc.account_no}`,
                    value: acc.account_no,
                  }))}
                  value={formik.values.account_1}
                  onChange={(value) => formik.setFieldValue("account_1", value)}
                />
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Payment 1</span>
                <span className="mandatory"></span>
                <Input
                  id="amount_1_received"
                  name="amount_1_received"
                  type="number"
                  prefix="₹"
                  style={{ width: "100%", height: 38 }}
                  // value={formik.values.amount_1_received}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <span className="hintText color-black">Payment Mode 2</span>
                <Radio.Group
                  id="payment_mode_2"
                  name="payment_mode_2"
                  style={{ width: "100%", marginTop: 10 }}
                  value={formik.values.payment_mode_2}
                  onChange={(e) =>
                    formik.setFieldValue("payment_mode_2", e.target.value)
                  }
                >
                  {paymentOptions.map((option, index) => (
                    <Radio key={index} value={option.mop}>
                      {option.mop}
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Bank</span>
                <Select
                  id="account_2"
                  name="account_2"
                  placeholder="Select an Account"
                  style={{ width: "100%", height: 38 }}
                  disabled={formik.values.payment_mode_2 === "Cash"}
                  options={accountList.map((acc) => ({
                    label: `${acc.bank_name} - ${acc.account_no}`,
                    value: acc.account_no,
                  }))}
                  value={formik.values.account_2}
                  onChange={(value) => formik.setFieldValue("account_2", value)}
                />
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Payment 2</span>
                <Input
                  id="amount_2_received"
                  name="amount_2_received"
                  type="number"
                  prefix="₹"
                  style={{ width: "100%", height: 38 }}
                  // value={formik.values.amount_2_received}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>

            <Row gutter={16} justify="end">
              <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Vendor Paid Weight (g)
                  </span>
                </div>
                <Input
                  type="text"
                  id="vendor_paid_weight"
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">Purity (%)</span>
                </div>
                <Input
                  type="text"
                  id="purity"
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Old Gold Weight (g)
                  </span>
                </div>
                <Input
                  type="text"
                  id="old_gold_weight"
                  disabled={true}
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values["old_gold_weight"]}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={8}>
                <span className="hintText color-black">Weight (g)</span>
                <Input
                  id="paid_weight"
                  disabled
                  style={{
                    width: "100%",
                    color: "#000",
                    fontSize: 14,
                  }}
                  value={formik.values.paid_weight}
                />
              </Col>
            </Row>
            {/* Action Buttons */}
            <Row gutter={16} justify="end" style={{ marginTop: "5%" }}>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  style={{ marginRight: 8, backgroundColor: "grey" }}
                  onClick={() => formik.resetForm()}
                >
                  Reset Fields
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ backgroundColor: "#1f43e5" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};

export default SettlementVendorCreate;
