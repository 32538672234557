import { Outlet } from "react-router-dom"
import Menu from "./Menu";
import Banner from "./common/Banner";
import axios from "axios";

const MainLayout = () => {
    const token = localStorage.getItem('AuthToken');
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return (
        <div className="completePage">
        <Menu />
        <div className="detailsContainer">
            <Banner />
            <Outlet />
        </div>
    </div>
    )
}

export default MainLayout;