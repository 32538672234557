import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Row, Select, Spin, Table, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import httpCall from '../../utils/api';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';

const { RangePicker } = DatePicker;

const StockReport = () => {
    const navigate = useNavigate();
    const [stockReportData, setStockReportData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [costCenterData, setCostCenterData] = useState([]);
    const [selectedCostCenter, setSelectedCostCenter] = useState(null);
    const [totals, setTotals] = useState({
        opg_qty: 0,
        opg_wgt: 0,
        inwd_qty: 0,
        inwd_wgt: 0,
        outwd_qty: 0,
        outwd_wgt: 0,
        cl_qty: 0,
        cl_wgt: 0
    });

    const formik = useFormik({
      initialValues: {
          from_date: moment().format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
          cost_center_id: ''
      },
      onSubmit: (values, { resetForm }) => {
      },
  });

    useEffect(() => {
        fetchCostCenters();
        fetchStockReport(
          formik.values.from_date,formik.values.to_date,formik.values.cost_center_id
        );
    }, []);

    useEffect(() => {
        calculateTotals();
    }, [stockReportData]);

    const calculateTotals = () => {
        const newTotals = stockReportData.reduce((acc, item) => ({
            opg_qty: acc.opg_qty + Number(item.opg_qty || 0),
            opg_wgt: acc.opg_wgt + Number(item.opg_wgt || 0),
            inwd_qty: acc.inwd_qty + Number(item.inwd_qty || 0),
            inwd_wgt: acc.inwd_wgt + Number(item.inwd_wgt || 0),
            outwd_qty: acc.outwd_qty + Number(item.outwd_qty || 0),
            outwd_wgt: acc.outwd_wgt + Number(item.outwd_wgt || 0),
            cl_qty: acc.cl_qty + Number(item.cl_qty || 0),
            cl_wgt: acc.cl_wgt + Number(item.cl_wgt || 0)
        }), {
            opg_qty: 0, opg_wgt: 0, inwd_qty: 0, inwd_wgt: 0,
            outwd_qty: 0, outwd_wgt: 0, cl_qty: 0, cl_wgt: 0
        });
        setTotals(newTotals);
    };

    const getValue = (list, key, id) => {
        const obj = list.filter(o => o.itemid === id);
        return obj.length > 0 ? obj[0][key] : 0;
    };

    const fetchStockReport = async (from, to, costCenterId) => {
        setLoading(true);
        try {
            let url = '/api/stock/report';
            const params = new URLSearchParams();
            
            if (from) params.append('from', from);
            if (to) params.append('to', to);
            if (costCenterId) params.append('cost_center_id', costCenterId);
            
            if (params.toString()) {
                url += `?${params.toString()}`;
            }

            const response = await httpCall({
                method: 'GET',
                url,
            });

            const { stocks, inwards, outwards } = response?.data;
            const stockReport = stocks.map(stock => ({
                itemid: stock.item_id,
                itemname: stock.itemname,
                opg_qty: parseFloat(stock.pcs) + parseFloat(getValue(outwards, 'outwd_qty', stock.item_id)) - parseFloat(getValue(inwards, 'inwd_qty', stock.item_id)),
                opg_wgt: parseFloat(stock.weight) + parseFloat(getValue(outwards, 'outwd_wgt', stock.item_id)) - parseFloat(getValue(inwards, 'inwd_wgt', stock.item_id)),
                inwd_qty: getValue(inwards, 'inwd_qty', stock.item_id),
                inwd_wgt: getValue(inwards, 'inwd_wgt', stock.item_id),
                outwd_qty: getValue(outwards, 'outwd_qty', stock.item_id),
                outwd_wgt: getValue(outwards, 'outwd_wgt', stock.item_id),
                cl_qty: stock.pcs,
                cl_wgt: stock.weight,
            }));

            setStockReportData(stockReport);
        } catch (error) {
            api.error({
                message: 'Error',
                description: 'Failed to fetch stock report data',
            });
            console.error('Error retrieving stock report data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCostCenters = async () => {
        try {
            const response = await httpCall({
                method: 'GET',
                url: '/api/costcenter/get',
            });
            if (response?.data) {
                setCostCenterData(response.data.map(cc => ({
                    label: cc.cost_center_name,
                    value: cc.cost_center_id
                })));
            }
        } catch (error) {
            api.error({
                message: 'Error',
                description: 'Failed to fetch cost centers',
            });
            console.error('Error fetching cost centers:', error);
        }
    };

    const handleCostCenterChange = (value) => {
        setSelectedCostCenter(value);
        fetchStockReport(
            formik.values.from_date,formik.values.to_date,
            value
        );
    };

const stockReportColumns = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.itemname.localeCompare(b.itemname),
            defaultSortOrder: 'ascend',
        },
        {
            title: <>Opening Stock <FolderOpenOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'opg_qty',
                    key: 'opg_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.opg_qty - b.opg_qty,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'opg_wgt',
                    key: 'opg_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.opg_wgt - b.opg_wgt,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                }
            ]
        },
        {
            title: <>Inward Stock <ArrowDownOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'inwd_qty',
                    key: 'inwd_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.inwd_qty - b.inwd_qty,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'inwd_wgt',
                    key: 'inwd_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.inwd_wgt - b.inwd_wgt,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                }
            ]
        },
        {
            title: <>Outward Stock <ArrowUpOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'outwd_qty',
                    key: 'outwd_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.outwd_qty - b.outwd_qty,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'outwd_wgt',
                    key: 'outwd_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.outwd_wgt - b.outwd_wgt,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                }
            ]
        },
        {
            title: <>Closing Stock <CloseOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'cl_qty',
                    key: 'cl_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.cl_qty - b.cl_qty,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'cl_wgt',
                    key: 'cl_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.cl_wgt - b.cl_wgt,
                    render: (text) => {
                        const formattedAmount = new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(parseFloat(text));
                        return formattedAmount;
                      },
                }
            ]
        },
    ];

    return (
        <>
            <PageTitle
                imagesrc={SalesImage}
                pageTitle="Daily Stock Statement"
                buttonList={[]}
            />
            {contextHolder}
            <div className="vendorListArea">
                <Row gutter={16} className="gutter-row" style={{ marginBottom: "30px" }}>
                    <Col span={7} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Select Date Range</span>
                        </div>
                        <RangePicker
                style={{ height: "38px" }}
                onChange={(e) => {
                  if (!!e) {
                    const start = e[0].format("YYYY-MM-DD");
                    const end = e[1].format("YYYY-MM-DD");
                    fetchStockReport(start, end,formik.values.cost_center_id);
                  }
                }}
              />
                        
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Managed By</span>
                        </div>
                        <Select
                            showSearch
                            placeholder="Select Manager"
                            style={{ width: "100%", height: "38px" }}
                            options={costCenterData}
                            value={selectedCostCenter}
                            onChange={handleCostCenterChange}
                            allowClear
                        />
                    </Col>
                </Row>
                <Spin
                    tip="Fetching Data From Server, Please Wait ..."
                    spinning={loading}
                    size="large"
                >
            <Row
              gutter={16}
              className="gutter-row"
              style={{ marginTop: "-20px", marginBottom: "50px" }}
            >
              <Col span={24} className="gutter-box">
                            <span style={{ fontSize: '15px', fontWeight: '600' }}>Stock Statement</span>
                <Table
                  dataSource={stockReportData}
                                size='small'
                  bordered={true}
                  columns={stockReportColumns}
                  rowKey="itemid"
                                scroll={{ y: '250px' }}
                  pagination={false}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} align="center">
                          <strong>Total</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.opg_qty)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.opg_wgt)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.inwd_qty)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.inwd_wgt)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.outwd_qty)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.outwd_wgt)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.cl_qty)}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8} align="center">
                          <strong>
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totals.cl_wgt)}
                          </strong>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                                style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </>
    );
};

export default StockReport;