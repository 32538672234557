import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, DatePicker, Form, Input, Popconfirm, Space, Spin, Table, Typography, notification, Modal } from 'antd';
import PageTitle from '../../common/PageTitle';
import ItemImage from '../../../assets/images/item.png';
import columnSearch from '../../common/columnSearch';
import httpCall from '../../../utils/api';
import '../../../css/container.css';
import '../../../css/SmallComponents.css';
import '../../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditableCell from '../../common/EditableCell';
import moment from 'moment';
import dayjs from "dayjs";
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;
const ItemTypeList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [itemTypeList, setItemTypeList] = useState([]);
	const [itemExportTypeList, setExportItemTypeList] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [isLoading, setIsLoading] = useState(false);
	const nameInputRef = useRef(null);
	const rateInputRef = useRef(null);
	const dateInputRef = useRef(new Date().toISOString().split('T')[0]); // Format as 'YYYY-MM-DD'
	const [date, setDate] = useState([moment(), moment()]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const { RangePicker } = DatePicker;
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.name === editingKey;

	useEffect(() => {
		fetchItemTypeList();
	}, []);

	const edit = (record) => {
		form.setFieldsValue({
			name: '',
			...record,
		});
		setEditingKey(record.name);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const update = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...itemTypeList];
			const index = newData.findIndex((item) => key === item.name);
			if (index > -1) {
				const item = newData[index];
				// Update API 
				handleUpdate(item.name, row.current_rate);

				newData.splice(index, 1, {
					...item,
					...row,
				});
				setItemTypeList(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setItemTypeList(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const handleDateChange = (date, dateString) => {
		if (date) {
			const formattedDate = date.format('YYYY-MM-DD'); // Format date as 'YYYY-MM-DD'
			fetchItemTypeList(formattedDate); // Pass formatted date to the API call
			setDate(date);
		} else {
			fetchItemTypeList(); // Fetch data without date filter
			setDate([moment(), moment()]);
		}
	};

	const exportAsExcel = (data) => {
		const formattedData = data.map(item => ({
			'Material Type': item.name,
			'Current Rate': parseFloat(item.current_rate).toFixed(2),
			'Updated Date': item.updated_date ? moment(item.updated_date).format("DD/MM/YYYY") : "",
		}));

		const worksheet = XLSX.utils.json_to_sheet(formattedData);
		const colWidths = [
			{ wch: 25 },  // Material Type
			{ wch: 15 },  // Current Rate
			{ wch: 20 }   // Updated Date
		];
		worksheet['!cols'] = colWidths;
		const headerStyle = {
			font: { bold: true, color: { rgb: "FFFFFF" } },
			fill: { fgColor: { rgb: "4472C4" } }
		};
		['A1', 'B1', 'C1'].forEach(cell => {
			if (worksheet[cell]) worksheet[cell].s = headerStyle;
		});

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Material Types');
		XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		XLSX.writeFile(workbook, 'Material_Types.xlsx');
	};

	const handleExport = () => {
		setIsModalVisible(true);
	};

	const handleModalOk = async () => {
		const [fromDate, toDate] = selectedDateRange;

		if (!fromDate || !toDate) {
			notification.error({
				message: 'Date Range Error',
				description: 'Please select both start and end dates.',
			});
			return; // Exit if the date range is invalid
		}

		setIsLoading(true); // Show loading while fetching
		try {
			await fetchExportItemTypeList(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'));
			setIsModalVisible(false);
		} catch (error) {
			notification.error({
				message: 'Export Failed',
				description: 'An error occurred while fetching data for export.',
			});
		} finally {
			setIsLoading(false); // Hide loading after fetching
		}
	};


	const handleModalCancel = () => {
		setIsModalVisible(false);
	};

	const fetchExportItemTypeList = (fromDate, toDate) => {
		setIsLoading(true);
		let url = '/api/itemtype/alllist';
		if (fromDate && toDate) {
			url += `?fromdate=${fromDate}&todate=${toDate}`;
		}

		return httpCall({
			method: 'GET',
			url: url,
		})
			.then((response) => {
				setIsLoading(false);
				if (response) {
					exportAsExcel(response.data);
				} else {
					console.error('Error retrieving item type list', response.message);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.error('Error retrieving item type list', error);
			});
	};

	const fetchItemTypeList = (date) => {
		setIsLoading(true);
		let url = '/api/itemtype/list';
		if (date) {
			url += `/${date}`; // Include the date parameter if it's provided
		}

		httpCall({
			method: 'GET',
			url: url,
		})
			.then((response) => {
				setIsLoading(false);
				if (response) {
					setItemTypeList(response?.data);
				} else {
					console.error('Error retrieving item type list', response.message);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.error('Error retrieving item type list', error);
			});
	};

	const openNotification = (msg, desc) => {
		api.open({
			message: msg,
			duration: 2,
			description: desc,
			icon: <CheckCircleFilled style={{ color: '#808000' }} />,
		});
	};

	const handleSave = () => {
		const nameValue = nameInputRef.current.input.value.trim();
		const rateValue = rateInputRef.current.input.value.trim();
		// Check if fields are empty
		if (!nameValue) {
			openErrorNotification('Validation Error', 'Material Type is required.');
			return;
		}

		if (!rateValue) {
			openErrorNotification('Validation Error', 'Current Rate is required.');
			return;
		}

		// Proceed with the API call if validation passes
		httpCall({
			method: 'POST',
			url: '/api/itemtype/create',
			payload: {
				name: nameValue,
				current_rate: rateValue,
				current_date: dateInputRef.current
			},
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Add Success!', 'New Type Added Successfully, Updating Grid.');
					nameInputRef.current.value = "";
					rateInputRef.current.value = "";
					fetchItemTypeList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 409) {
					// Handle conflict error when the item type already exists
					openErrorNotification('Add Failed', 'Item Type already exists');
				} else {
					// Handle other types of errors
					console.error(error);
					openErrorNotification('Error', 'An error occurred while adding the item type.');
				}
			});
	};

	const openErrorNotification = (message, description) => {
		api.error({
			message: message,
			duration: 4,
			description: description,
		});
	};


	const handleUpdate = (name, current_rate) => {
		setIsLoading(true);
		httpCall({
			method: 'PUT',
			url: '/api/itemtype/edit',
			payload: {
				name,
				current_rate,
				current_date: dateInputRef.current
			},
		})
			.then((response) => {
				setIsLoading(false);
				if (response.data.success) {
					openNotification('Update Success!', 'Material Type Updated Successfully, Updating Grid.');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.error(error);
			});
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/itemtype/delete/' + id,
		}).then(reponse => {
			openNotification('Delete Success !', 'Material Type Deleted Successfully, Refreshing Grid. ');
			fetchItemTypeList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};

	const handleTotal = (total, range) => (
		<div style={{ fontFamily: 'Poppins', fontSize: '15px' }}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
	);

	const columnsNew = [
		{
			title: 'Material Type',
			dataIndex: 'name',
			key: 'name',
			...tableColumnSearch('name'),
			align: 'center',
			width: 300,
			sorter: (a, b) => a.name.localeCompare(b.name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',
			...tableColumnSearch('current_rate'),
			align: 'center',
			editable: true,
			width: 300,
			render: (text) => {
				const formattedAmount = new Intl.NumberFormat("en-US", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(parseFloat(text));
				return formattedAmount;
			},
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => update(record.name)}
							style={{
								marginRight: 8,
							}}
						> Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Space size="middle" disabled={editingKey !== ''}>
						<EditOutlined
							style={{
								cursor: 'pointer',
								fontSize: '20px',
								color: '#08c',
							}}
							onClick={() => edit(record)}
						/>
						<Popconfirm
							title="Are you sure you want to delete?"
							onConfirm={() => handleDelete(record.name)}
						>
							<DeleteOutlined
								style={{ fontSize: '20px', color: '#FF6868' }}
							/>
						</Popconfirm>
					</Space>
				);
			}
		},
	];

	const mergedColumns = columnsNew.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	const CategoryAdd = () => {
		return (
			<div style={{ marginTop: '2rem', marginLeft: '2rem', width: '100%', display: 'flex', gap: '20px', justifyContent: 'start', alignItems: 'center' }}>
				<Space direction='vertical'>
					<span style={{ fontSize: '13px' }}>Material Type</span>
					<Input
						type="text"
						placeholder='Enter Material Type'
						style={{ width: '250px', marginBottom: '30px' }}
						ref={nameInputRef}
					/>
				</Space>
				<Space direction='vertical'>
					<span style={{ fontSize: '13px' }}>Current Rate</span>
					<Input
						type="number"
						placeholder='Enter Current Market Rate'
						style={{ width: '250px', marginBottom: '30px' }}
						ref={rateInputRef}
					/>
				</Space>
				{/* <Space direction='vertical'>
					<span style={{fontSize: '13px'}}>Date</span>
					<Input
						type="date"
						placeholder='Enter Current Market Rate'	
						style={{ width: '250px', marginBottom: '30px' }}
						ref={dateInputRef}													
					/>
				</Space> */}
				<Button type='primary'
					style={{ backgroundColor: 'green' }}
					onClick={handleSave}
					icon={<PlusOutlined />}>
					Add Material Type
				</Button>
			</div>
		)
	}

	return (
		<>
			<PageTitle
				imagesrc={ItemImage}
				pageTitle="Material Types"
				buttonList={[{
					buttonName: 'Back',
					className: 'btn-back',
					url: '/slj/items'
				}]}
			/>
			{contextHolder}
			<CategoryAdd />
			<Modal
				title="Select Date Range"
				visible={isModalVisible}
				onOk={handleModalOk}
				onCancel={handleModalCancel}
			>
				<RangePicker
					onChange={setSelectedDateRange}
					format="DD/MM/YYYY"
				/>
			</Modal>
			<div className='categoryList' style={{ width: '850px' }}>
				<Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Col span={16} className="gutter-box">
						<div>
							<span className="hintText color-black">Select Date</span>
						</div>
						<DatePicker
							style={{ height: '38px' }}
							onChange={handleDateChange}
							format="DD/MM/YYYY"
							allowClear
						/>
					</Col>
					<Col>
						<Button
							type="primary"
							style={{ backgroundColor: 'green' }}
							onClick={handleExport}>
							Export
						</Button>
					</Col>
				</Row>


				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={isLoading} size="large">
					<Form form={form} component={false}>
						<Table
							dataSource={itemTypeList}
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							bordered
							columns={mergedColumns}
							rowKey="name"
							rowClassName="editable-row"
							pagination={{
								showSizeChanger: true,
								position: ['bottomCenter'],
								pageSizeOptions: ['10', '20', '30'],
								showTotal: handleTotal
							}}
							scroll={{ y: 500 }}
							size='small'
							style={{ fontFamily: 'Poppins' }}
						/>
					</Form>
				</Spin>
			</div>
		</>
	);

};

export default ItemTypeList;