import axios from "axios";
import { serverEndpoint } from "../environments/environment";

const httpCall = ({
    method,
    url,
    payload,
    headers
}) => {
    const getRequestConfig = () => {
        const config = { method, url: serverEndpoint + url};
        if(payload) {
            config['data'] = payload;
        }
        if(headers) {
            config['headers'] = headers
        }
        return config;
    }
    
    return axios(getRequestConfig());
}

export default httpCall;