import { Form, Input } from 'antd';

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	...restProps
  }) => {
	const inputNode = <Input style={{textAlign: 'center'}}/>;
	return (
	  <td {...restProps}>
		{editing ? (
		  <Form.Item
			name={dataIndex}
			style={{
			  margin: 0,
			}}
			rules={[
			  {
				required: true,
				message: `Please Input ${title}!`,
			  },
			]}
		  >
			{inputNode}
		  </Form.Item>
		) : (
		  children
		)}
	  </td>
	);
  };

  export default EditableCell;