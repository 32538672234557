import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, Divider, Flex, Input, InputNumber, Modal, Radio, Row, Select, Space, Statistic, Switch, Table, notification } from 'antd';
import { CheckCircleFilled, ClearOutlined, DeleteFilled, EyeOutlined, EyeTwoTone, FileImageOutlined, PlusOutlined, ProfileOutlined, ProfileTwoTone, SaveOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/sales.png';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

const {Option} = Select;

const SalesForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [costCenterList, setCostCenterList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [itemDataList, setItemDataList] = useState([]);
    const [itemDataOptions, setItemDataOptions] = useState([]);
    const [custMobileOptions, setCustMobileOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [salesItemList, setSalesItemList] = useState([]);
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
    const [plants, setPlants] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [quotationNrList, setQuotationNrList] = useState([]);
    const [quotationDetails, setQuotationDetails] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });

    const formik = useFormik({
		initialValues: {
            sales_order_no_inc: '',
            sales_order_no: '',
            customer_id: '',
            mobile_number: '',
            sales_order_date: moment(),
            opg_pure: '',
            opg_cash: '',
            reference_no: '',
            thru: '',
            total_sales_item_amount: '',
            total_old_gold_amount: '',
            discount: '',
            total_sales_amount: '',
            payment_mode_1: 'Cash',
            account_1: '',
            sales_person_id: '',
            sales_person: '',
            customer_name: '',
            customer_address: '',
            plant_code: '',
            plant_name: '',
            quotationHeader: ''
		},
		onSubmit: (values, {resetForm}) => {
            // if(!hasValidated(values)) {
            //     return;
            // }
			if(location?.state?.id) {
				updateSaleData(values);
			} else {
				// createSaleData(values, resetForm);
                addNewSaleEntry(formik.values.quotation_no, resetForm);
			}
		},
	});

    const salesItemFormik = useFormik({
		initialValues: {
            sales_item_id: '',
            sales_item_name: '',
            pcs: '',
            weight: '',
            igst: '',
            cgst: '',
            sgst: '',
            tax_amount: '',
            amount: '',
            stone: '',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const salesItemReturnFormik = useFormik({
		initialValues: {
            sales_item_id: '',
            sales_item_name: '',
            pcs: '',
            weight: '',
            amount: '',
            stone: '',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const hasValidated = (values) => {
        if(values.customer_id === '') {
            openErrorNotification('Please Select a Customer.');
            return false;
        }
        if(values.plant_code === '') {
            openErrorNotification('Please Select a Plant.');
            return false;
        }
        if(values.sales_person_id === '') {
            openErrorNotification('Please Select Sales Person');
            return;
        }
        if(salesItemList.length === 0) {
            openErrorNotification('Please add a sales item');
            return false;
        }

        return true;
    }

    useEffect(() => {
        fetchCostCenter();
        fetchCustomerList();
        fetchItemDataList();
        fetchPlantList();
        fetchQuotationNrList();
        fetchNextBillNo();
        fetchPaymentOptions();
        fetchAccountList();
        fetchItemTyeps();
		if(location?.state?.id) {
			httpCall({
				method: 'GET',
				url: '/api/sales/get?id=' + location?.state?.id,
			})
				.then((response) => {
                    formik.setValues(response.data);                    
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, []);

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                console.log(response?.data)
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const fetchItemTyeps = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				if (response) {
                    setItemTypes(response?.data);
				} else {
					console.error(
						'Error retrieving item type list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving item type list', error);
				setLoading(false);
			});
	};

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error(
						'Error retrieving account list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    useEffect(() => {
        calculateTotal();
	}, [salesItemList, salesReturnItemList]);

    useEffect(() => {
        if(!!salesItemFormik.values.weight && !!salesItemFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemFormik.values.weight || 0);
            let waste = parseFloat(salesItemFormik.values.waste || 0);
            const waste_unit = salesItemFormik.values.waste_unit;
            const stone = parseFloat(salesItemFormik.values.stone || 0);
            const mc_amount = parseFloat(salesItemFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            let amt = ((weight + waste) * current_rate) + stone + mc_amount;

            const igst = parseFloat(salesItemFormik.values.cgst || 0) + parseFloat(salesItemFormik.values.sgst || 0);
            const igstAmt = (amt * igst)/100;
            salesItemFormik.setFieldValue('tax_amount', igstAmt.toFixed(3));
            amt = amt + igstAmt;
            salesItemFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemFormik.values.weight,
        salesItemFormik.values.waste, 
        salesItemFormik.values.waste_unit,
        salesItemFormik.values.stone, 
        salesItemFormik.values.mc_amount
    ]);

    useEffect(() => {
        if(!!salesItemReturnFormik.values.weight && !!salesItemReturnFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemReturnFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemReturnFormik.values.weight || 0);
            let waste = parseFloat(salesItemReturnFormik.values.waste || 0);
            const waste_unit = salesItemReturnFormik.values.waste_unit;
            const stone = parseFloat(salesItemReturnFormik.values.stone || 0);
            const mc_amount = parseFloat(salesItemReturnFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            const amt = ((weight - waste) * current_rate) - stone - mc_amount;
            salesItemReturnFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemReturnFormik.values.weight,
        salesItemReturnFormik.values.current_rate,
        salesItemReturnFormik.values.waste, 
        salesItemReturnFormik.values.waste_unit,
        salesItemReturnFormik.values.stone, 
        salesItemReturnFormik.values.mc_amount
    ]);

	const fetchPlantList = () => {
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
			.then((response) => {
                setPlants(response?.data);
                setPlantOptions(response?.data.map(p => ({label: p.plant_name, value: p.plant_code})))
			})
			.catch((error) => {
			});
	};

    const fetchQuotationNrList = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getQuotationNrList',
		})
			.then((response) => {
                setQuotationNrList(response?.data.map(qt => ({label: qt.quotation_no, value: qt.quotation_no})))
			})
			.catch((error) => {
			});
	};

    const fetchQuotationDetails = (quotation_no) => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getQuotationDetails?qtNo=' + quotation_no,
		})
			.then((response) => {
                setQuotationDetails(response);
                const quotation = response.data.quotation;
                formik.setFieldValue('total_sales_item_amount', quotation.total_sales_item_amount); 
                formik.setFieldValue('total_old_gold_amount', quotation.total_old_gold_amount);
                formik.setFieldValue('balance', quotation.total_sales_item_amount - quotation.total_old_gold_amount);
                formik.setFieldValue('discount', quotation.discount);
                formik.setFieldValue('total_sales_amount', quotation.total_sales_amount);

                const cust = customerList.find(c => c.id === quotation.customer_id);
                formik.setFieldValue("customer_id", cust.id);
                formik.setFieldValue("customer_name", cust.company_name);
                formik.setFieldValue("customer_address", cust.address);
                formik.setFieldValue("mobile_number", {label: cust.mobile_number, value: cust.mobile_number});

                const plant = plantOptions.find(p => p.value === quotation.plant_code);
                console.log(plantOptions)
                formik.setFieldValue("plant_name", plant);

                const salesPerson = costCenterList.find(cc => cc.value === quotation.sales_person_id);
                console.log(costCenterList)
                formik.setFieldValue("sales_person", salesPerson);

                formik.setFieldValue("opg_pure", quotation.opg_pure);
                formik.setFieldValue("opg_cash", quotation.opg_cash);
                formik.setFieldValue("reference_no", quotation.reference_no);
                formik.setFieldValue("thru", quotation.thru);

                addItemFromQuotation(response.data.items, setSalesItemList, false);
                addItemFromQuotation(response.data.ogItems, setSalesReturnItemList, true);
			})
			.catch((error) => {
			});
	};

    const fetchCostCenter = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				if (response) {
                    setCostCenterList(response?.data.map(c => ({label: c.cost_center_name, value: c.cost_center_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data);
                    setCustMobileOptions(response?.data.map(c => ({label: c.mobile_number, value: c.mobile_number})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchItemDataList = () => {
		httpCall({
			method: 'GET',
			url: '/api/item/sale/list',
		})
			.then((response) => {
				if (response) {
                    setItemDataList(response?.data);
                    setItemDataOptions(response?.data.map(i => ({label: i.itemname, value: i.itemname})));
				} else {
					console.error(
						'Error retrieving item data list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving item data list:', error);
			});
	};

    const fetchNextBillNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getSalesOrderNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('sales_order_no_inc', response.data);
                    formik.setFieldValue('sales_order_no', `${response.data}/${getCurrentDate()}`);
				} else {
					console.error(
						'Error retrieving next billno:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving next billno:', error);
			});
	};

    const getCurrentDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${month<10?`0${month}`:`${month}`}${year}`;
      }

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const addNewSaleEntry = (quotation_no, handleReset) => {
		httpCall({
			method: 'PUT',
			url: '/api/sales/salesOrderNew',
			payload: {quotation_no, payment_mode: formik.values.payment_mode_1, account_1: formik.values.account_1}
		})
			.then((response) => {
				if (response) {
					openNotification('Sales Data Created Successfully, Goto list view or Create another one. ');		
					handleReset();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const createSaleData = (values, handleReset) => {
        const sales_order_date = moment(values.sales_order_date).format('YYYY-MM-DD');
		httpCall({
			method: 'POST',
			url: '/api/sales/salesOrder',
			payload: {...values, sales_order_date, sales_items: salesItemList, sales_items_return: salesReturnItemList}
		})
			.then((response) => {
				if (response) {
					openNotification('Sales Data Created Successfully, Goto list view or Create another one. ');		
					handleReset();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const updateSaleData = (values) => {
		httpCall({
			method: 'PUT',
			url: '/api/sales/edit',
			payload: {...values, itemid: location?.state?.id},
		})
			.then((response) => {
				if (response.data.success) {
					openNotification('Sales Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/sales'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const calculateTotal = () => {
        const salesBillAmount = salesItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);
        const oldGoldAmount = salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);
        const discount = parseFloat(formik.values.discount || 0);

        formik.setFieldValue('total_sales_item_amount', salesBillAmount); 
        formik.setFieldValue('total_old_gold_amount', oldGoldAmount);
        formik.setFieldValue('balance', salesBillAmount - oldGoldAmount);
        formik.setFieldValue('total_sales_amount', (salesBillAmount - oldGoldAmount - discount));
    }

    const salesItemColumns = [
        {
			title: 'Item Name',
			dataIndex: 'sales_item_name',
			key: 'sales_item_name',
			align: 'center',
			width: 250
		},
        {
			title: 'PCS',
			dataIndex: 'pcs',
			key: 'pcs',		
			align: 'center',
			width: 100
		},
        {
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',		
			align: 'center',
			width: 150
		},
        {
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',		
			align: 'center',
			width: 150
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',		
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
                (
                    <Space size="middle">
                        <EyeTwoTone 
                            onClick={() => {
                                let tempFormik = salesItemFormik;
                                if(record.isSalesReturn) {
                                    // const idx = salesReturnItemList.findIndex(item => item.sales_item_id === record.sales_item_id);
                                    // salesReturnItemList.splice(idx, 1)
                                    // setSalesReturnItemList([...salesReturnItemList]);
                                    tempFormik = salesItemReturnFormik;
                                } else {
                                    // const idx = salesItemList.findIndex(item => item.sales_item_id === record.sales_item_id);
                                    // salesItemList.splice(idx, 1);
                                    // setSalesItemList([...salesItemList]);
                                    tempFormik = salesItemFormik;
                                }
                                const item = itemDataList.find(i => i.itemname === record.sales_item_name);                                
                                tempFormik.setFieldValue('item_name', {label: record.sales_item_name, value: record.sales_item_name});
                                tempFormik.setFieldValue('product', item.category_name);
                                tempFormik.setFieldValue('sub_product', item.sub_category_name);
                                tempFormik.setFieldValue('pcs', record.pcs);
                                tempFormik.setFieldValue('weight', record.weight);
                                tempFormik.setFieldValue('current_rate', record.current_rate);
                                tempFormik.setFieldValue('igst', record.igst);
                                tempFormik.setFieldValue('sgst', record.sgst);
                                tempFormik.setFieldValue('cgst', record.cgst);
                                tempFormik.setFieldValue('tax_amount', record.tax_amount);
                                tempFormik.setFieldValue('amount', record.amount);
                                tempFormik.setFieldValue('stone', record.stone);
                                tempFormik.setFieldValue('waste', record.waste);
                                tempFormik.setFieldValue('waste_unit', record.waste_unit);
                                tempFormik.setFieldValue('huid', record.huid);
                                tempFormik.setFieldValue('mc_amount', record.mc_amount);
                                tempFormik.setFieldValue('other_details', record.other_details);
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#B31312'}}/>
                    </Space>
                )
		  },
	];

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const salesItemTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Table
						dataSource={datasoure}
						columns={salesItemColumns}
                        pagination={{position: ['bottomCenter'], pageSize: 2}}
						rowKey="sales_item_name"
                        size='small'
                        bordered="true"						
						scroll={{ y: 100 }}
						style={{fontFamily: 'Poppins'}}/>
                    </Col>
                </Row>
            </>
        ) : null
    }

    const salesOrderForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Quotation Nr</span>                   
                        </div> 
                        <Select
                            id="quotation_no"
                            placeholder="Select Quotation"
                            style={{ width: '100%', height: '38px' }}
                            options={quotationNrList}
                            onChange={(e)=> {
                                formik.setFieldValue("quotation_no", e);
                                fetchQuotationDetails(e);
                            }}
                        />   
                    </Col>
                    <Col span={4} offset={12} className="gutter-box" style={{textAlign: 'right'}}>
                        <div>
                            <span className="hintText">Bill Nr</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="sales_order_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', color: '#000000', padding: '5px 2px', fontSize: '14px', fontWeight: '700',  textAlign: 'right'}}
                            value={formik.values['sales_order_no']}
                        />                                
                    </Col>
                </Row>
                <Divider  variant="dashed" style={{  borderColor: '#7cb305' }} dashed >Sale Details</Divider>
                <Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="no"
                            showSearch
                            disabled={true}
                            placeholder="Select a Number"
                            filterOption={filterOption}
                            style={{ width: '100%', height: '38px' }}
                            options={custMobileOptions}
                            value={formik.values.mobile_number}
                            onChange={(e)=> {
                                const cust = customerList.find(c => c.mobile_number === e);
                                formik.setFieldValue("customer_id", cust.id);
                                formik.setFieldValue("customer_name", cust.company_name);
                                formik.setFieldValue("customer_address", cust.address);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Sale Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}                            
                            disabled={true}
                            name='sales_order_date'
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.sales_order_date}
                        />                             
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_name"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_name}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_address"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_address}
                        />                                
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Plant</span>
                            <span className="mandatory"></span>                         
                        </div> 
                        <Select
                            id="plant_code"
                            disabled={true}
                            placeholder="Select a Plant"
                            style={{ width: '100%', height: '38px' }}
                            options={plantOptions}
                            value={formik.values.plant_name}
                            onChange={(e)=> {
                                formik.setFieldValue("plant_code", e);
                                const plant = plants.find(p => p.plant_code === e);
                                const qHeader = `${plant.plant_name}, ${plant.address}, ${plant.city}-${plant.zipcode}, ${plant.phone_no}`;
                                formik.setFieldValue("quotationHeader", qHeader);
                            }}
                        />   
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Sales Person Name</span> 
                            <span className="mandatory"></span>                       
                        </div> 
                        <Select
                            id="sales_person_id"
                            placeholder="Select a Name"
                            disabled={true}
                            style={{ width: '100%', height: '38px' }}
                            options={costCenterList}
                            value={formik.values.sales_person}
                            onChange={(e)=> {
                                formik.setFieldValue("sales_person_id", e);
                            }}
                        />   
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">OpgPure</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="opg_pure"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.opg_pure}
                        />    
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">OpgCash</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="opg_cash"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.opg_cash}
                        />                             
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Reference No</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="reference_no"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.reference_no}
                        />                             
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Thru</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="thru"
                            style={{ width: '100%'}}
                            onChange={formik.handleChange}
                            value={formik.values.thru}
                        />                             
                    </Col>
                </Row>
            </>
        )
    }

    const updateItemFields = (item_name, form, isReturn) => {
        form.setFieldValue("item_name", item_name);
        const item = itemDataList.find(i => i.itemname === item_name);
        form.setFieldValue("item_id", item.itemid);
        form.setFieldValue("product", item.category_name);
        form.setFieldValue("sub_product", item.sub_category_name);
        form.setFieldValue("unit", item.unit);
        if(!isReturn) {
            form.setFieldValue("current_rate", item.current_rate);
        }        
        form.setFieldValue("igst", item.igst);
        form.setFieldValue("cgst", item.cgst);
        form.setFieldValue("sgst", item.sgst);
    }

    const addItemFromQuotation = (items, setItemList, isReturn) => {
        const tempItems = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const salesItem = {
                isSalesReturn: isReturn,
                sales_item_id: item.item_id,
                sales_item_name: itemDataList.find(i => i.itemid = item.sales_item_id).itemname,
                pcs: item.pcs || 0,
                weight: item.weight,
                current_rate: item.current_rate,
                amount: item.amount,
                stone: item.stone,
                waste: item.waste,
                waste_unit: item.waste_unit,
                mc_amount: item.mc_amount,
                huid: item.huid,
                igst: item.igst,
                cgst: item.cgst,
                sgst: item.sgst,
                tax_amount: item.tax_amount,
                other_details: item.other_details
            }
            tempItems.push(salesItem);
        }
        setItemList(tempItems);
    }

    const addNewItem = (form, itemList, setItemList, isReturn) => {
        const salesItem = {
            isSalesReturn: isReturn,
            sales_item_id: form.values.item_id,
            sales_item_name: form.values.item_name,
            pcs: form.values.pcs || 0,
            weight: form.values.weight,
            current_rate: form.values.current_rate,
            amount: form.values.amount,
            stone: form.values.stone,
            waste: form.values.waste,
            waste_unit: form.values.waste_unit,
            mc_amount: form.values.mc_amount,
            huid: form.values.huid,
            igst: form.values.igst,
            cgst: form.values.cgst,
            sgst: form.values.sgst,
            tax_amount: form.values.tax_amount,
            other_details: form.values.other_details
        }
        if(salesItem.sales_item_name !== '' && salesItem.weight !== '' && (!isReturn || salesItem.current_rate)) {
            setItemList([...itemList, salesItem]);
            form.resetForm();
            form.setFieldValue('item_name', '')
        } else {
            openErrorNotification('Please Enter Required Item Fields.');
        }
    }

    const wasteTypes = (form) => (
        <Select value={form.values.waste_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("waste_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const salesItemForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <div>
                            <span className="hintText">Item Name</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="item_name"
                            disabled={true}
                            placeholder="Select an Item"
                            style={{ width: '100%', height: '38px' }}
                            options={itemDataOptions}
                            value={salesItemFormik.values.item_name}
                            onChange={(e)=> {
                                updateItemFields(e, salesItemFormik, false);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Category</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="product"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.product}
                        />     
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Sub Category</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="sub_product"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.sub_product}
                        />                                
                    </Col>
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">Unit</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="unit"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.unit}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={3} className="gutter-box">
                        <div>
                            <span className="hintText">PCS</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="pcs"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.pcs}
                        />    
                    </Col>
                    <Col span={7} className="gutter-box">
                        <Space.Compact style={{width: '100%'}}>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Weight</span>   
                                    <span className="mandatory"></span>                         
                                </div> 
                                <Input
                                    disabled={true}
                                    type="text"
                                    id="weight"
                                    style={{ width: '100%'}}
                                    onChange={salesItemFormik.handleChange}
                                    value={salesItemFormik.values.weight}
                                />   
                            </div>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Current Market Rate</span>                            
                                </div> 
                                <Input
                                    type="text"
                                    prefix='₹'
                                    id="current_rate"
                                    disabled= {true}
                                    style={{ width: '100%', padding: '7px', color: '#000000'}}
                                    value={salesItemFormik.values.current_rate}
                                />  
                            </div>
                        </Space.Compact>
                    </Col> 
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">CGST (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="cgst"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.cgst}
                        />    
                    </Col>
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">SGST (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="sgst"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.sgst}
                        />    
                    </Col>             
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Tax Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="tax_amount"
                            style={{ width: '100%', color: '#000000'}}
                            value={salesItemFormik.values.tax_amount}
                        />    
                    </Col>       
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            prefix='₹'
                            id="amount"
                            disabled= {true}
                            style={{ width: '100%', padding: '7px', color: '#000000'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.amount}
                        />                             
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Stone</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="stone"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.stone}
                        />     
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste</span>                                                
                        </div>                                                
                        <Input
                            type="text"
                            disabled={true}
                            addonAfter={wasteTypes(salesItemFormik)}
                            id="waste"
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.waste}
                        />                                
                    </Col>
                    {/* <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="touch"
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.touch}
                        />                                
                    </Col> */}
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">MC Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="mc_amount"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.mc_amount}
                        />                                
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">HUID</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="huid"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.huid}
                        />                                
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Other Details</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="other_details"
                            disabled={true}
                            style={{ width: '100%'}}
                            onChange={salesItemFormik.handleChange}
                            value={salesItemFormik.values.other_details}
                        />     
                    </Col> 
                </Row>
                {/* <Row gutter={16} className="gutter-row" justify="center" style={{margin: '20px 0px'}}>
                    <Col span={7} className="gutter-box" style={{textAlign: 'center'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                addNewItem(salesItemFormik, salesItemList, setSalesItemList, false);
                            }}
                            style={{ width: '100%', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                                ADD ITEM
                        </Button>
                    </Col>                  
                </Row> */}
                {salesItemTable(salesItemList)}
            </>
        )
    }

    const salesItemReturnForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <div>
                            <span className="hintText">Item Name</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="item_name"
                            disabled={true}
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={itemDataOptions}
                            value={salesItemReturnFormik.values.item_name}
                            onChange={(e)=> {
                                updateItemFields(e, salesItemReturnFormik, true);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Category</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="product"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.product}
                        />     
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Item Sub Category</span>                            
                        </div>  
                        <Input
                            type="text"
                            id="sub_product"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.sub_product}
                        />                                
                    </Col>
                    <Col span={2} className="gutter-box">
                        <div>
                            <span className="hintText">Unit</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="unit"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.unit}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">PCS</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="pcs"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.pcs}
                        />    
                    </Col>
                    <Col span={12} className="gutter-box">
                        <Space.Compact style={{width: '100%'}}>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Weight</span>
                                    <span className="mandatory"></span>                           
                                </div> 
                                <Input
                                    type="text"
                                    disabled={true}
                                    id="weight"
                                    style={{ width: '100%'}}
                                    onChange={(e) => {
                                        salesItemReturnFormik.setFieldValue('weight', e.target.value);
                                        const amount = parseFloat(e.target.value || 0) * parseFloat(salesItemReturnFormik.values.current_rate || 0);
                                        salesItemReturnFormik.setFieldValue('amount', amount.toFixed(3));
                                    }}
                                    value={salesItemReturnFormik.values.weight}
                                />   
                            </div>
                            <div style={{width: '50%'}}>
                                <div>
                                    <span className="hintText">Current Market Rate</span>   
                                    <span className="mandatory"></span>                         
                                </div> 
                                <Input
                                    type="text"
                                    disabled={true}
                                    prefix='₹'
                                    id="current_rate"                                    
                                    style={{ width: '100%', padding: '7px', color: '#000000'}}
                                    value={salesItemReturnFormik.values.current_rate}
                                    onChange={salesItemReturnFormik.handleChange}
                                />  
                            </div>
                        </Space.Compact>
                    </Col>                   
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            prefix='₹'
                            id="amount"
                            disabled={true}
                            style={{ width: '100%', padding: '7px', color: '#000000'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.amount}
                        />                             
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Stone</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="stone"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.stone}
                        />     
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            addonAfter={wasteTypes(salesItemReturnFormik)}
                            id="waste"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.waste}
                        />                                
                    </Col>
                    {/* <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Waste (%)</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="touch"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.touch}
                        />                                
                    </Col> */}
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">MC Amount</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="mc_amount"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.mc_amount}
                        />                                
                    </Col>
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">HUID</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="huid"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.huid}
                        />                                
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Other Details</span>                            
                        </div> 
                        <Input
                            type="text"
                            disabled={true}
                            id="other_details"
                            style={{ width: '100%'}}
                            onChange={salesItemReturnFormik.handleChange}
                            value={salesItemReturnFormik.values.other_details}
                        />     
                    </Col>  
                </Row>
                {/* <Row gutter={16} className="gutter-row" justify="center" style={{margin: '20px 0px'}}>
                    <Col span={7} className="gutter-box" style={{textAlign: 'center'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                addNewItem(salesItemReturnFormik, salesReturnItemList, setSalesReturnItemList, true);                                
                            }}
                            style={{ width: '100%', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                                ADD RETURN ITEM
                        </Button>
                    </Col>                  
                </Row> */}
                {salesItemTable(salesReturnItemList)}
            </>
        )
    }

    const salesForm = () => {
        return (
            <>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">Bill Amount</span>                       
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="total_sales_item_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.total_sales_item_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">OLD Gold Amount</span>                      
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="total_old_gold_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.total_old_gold_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">Balance</span>                        
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber                               
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="balance"
                            className='input-number-with-prefix'                                
                            onChange={formik.handleChange}
                            value={formik.values.balance}
                        />
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">Discount</span>                    
                    </Col>
                    <Col span={6} className="gutter-box">
                        <Input
                            type='text'
                            prefix='₹'
                            id="discount"
                            style={{ height: '38px' }}
                            onChange={(e) => {
                                formik.setFieldValue('discount', e.target.value);
                                formik.setFieldValue('total_sales_amount', parseFloat(formik.values.balance) - parseFloat(e.target.value || 0));
                            }}
                            value={formik.values.discount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE</span>                            
                        </div> 
                        <Radio.Group 
                            style={{marginTop: '5px', fontSize:' 16px'}}
                            onChange={(e) => {formik.setFieldValue('payment_mode_1', e.target.value)}} value={formik.values.payment_mode_1}>
                            {paymentOptions.map((option, index) => <Radio key={index} style={{fontSize:'13px', fontFamily:'poppins'}} value={option.mop}>{option.mop}</Radio>)}                
                        </Radio.Group>
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Bank</span>                            
                        </div> 
                        <Select
                            id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
                            placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
                            options={accountList}
                            onChange={(e)=> {
                                formik.setFieldValue("account_1", e);
                            }}
                        />
                    </Col>
                    <Col span={8} className="gutter-box" style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'start'}}>
                        <Statistic 
                            prefix='₹'
                            title="Total Amount" 
                            value={formik.values.total_sales_amount} 
                            precision={3}
                            style={{textAlign:'right', border: '1px dotted #d3d3d3', padding: '10px'}}
                            valueStyle={{ width: '275px', fontSize: '28px', textAlign:'right', color: '#000000', fontWeight: 'bolder'}} />
                    </Col>
                </Row>
            </>
        )
    }

    const createNewFormSection = () => {
		return (
			<>
                {salesOrderForm()}
				<Collapse 
                    style={{marginTop: '30px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'SALES',
                            children: salesItemForm(),
                        }
                    ]}>
                </Collapse>
				<Collapse 
                    style={{marginTop: '20px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'OLD GOLD RETURN',
                            children: salesItemReturnForm(),
                        }
                    ]}>
                </Collapse>
                <Card style={{marginTop: '35px'}}>
                    {salesForm()}
                </Card>                
			</>
		);
	};

    const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

    const salesAmountWOTax = () => parseFloat(formik.values.total_sales_item_amount) - salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0);

    const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0);  

    const getPageMargins = () => {
		return `@page { size: 80mm; margin: 10px !important; }`;
	};

    const EstimationReport = () => {    
        return (
            <>
                <div style={{display: 'none'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}} className='estimation-container' ref={componentRef}>
                        <style>{getPageMargins()}</style>
                        <div style={{textAlign: 'center'}}>
                            <span style={{fontSize: '14px', fontWeight: '700'}}>{formik.values.quotationHeader}</span>
                            <div style={{fontSize: '14px', fontWeight: '500', paddingTop: '15px'}}>Quotation</div>
                            <hr style={{border: '1px dashed #d3d3d3'}}/>

                            <Row gutter={16} className="gutter-row">
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">{moment(formik.values.sales_order_date).format('DD/MM/YYYY')}</span>                    
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">G.Rate:&nbsp;</span> 
                                    <span className="saleBillText">{getCurrentRate('GOLD')}</span> 
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">QT No &nbsp;</span>
                                    <span className="saleBillText">{formik.values.sales_order_no_inc}</span>                 
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">S.Rate:&nbsp;</span> 
                                    <span className="saleBillText">{getCurrentRate('SILVER') || 'NA'}</span> 
                                </Col>
                            </Row>
                            
                            { salesItemList.map(si => (
                                <>
                                    <hr style={{border: '1px dashed #d3d3d3'}}/>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                        <Col span={24} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText" style={{fontSize: '16px', fontWeight: '600'}}>{si.sales_item_name}</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                        <Col span={24} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">HM</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">Weight</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.weight} Gm</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">Waste</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.waste} Gm</span>                  
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '10px'}}>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">MC @</span>                  
                                        </Col>
                                        <Col span={6} style={{display: 'flex', justifyContent: 'start'}}>
                                            <span className="saleBillText">{si.mc_amount}</span>                  
                                        </Col>
                                        <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                                            <span className="saleBillText">{si.amount - si.tax_amount}</span>                  
                                        </Col>
                                    </Row>
                                </>
                            )) }
                            <hr style={{border: '1px dashed #d3d3d3'}}/>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={12} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Gross Amount</span>                  
                                </Col>
                                <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{salesAmountWOTax()}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">GST &nbsp;</span>
                                    <span className="saleBillText">{salesItemList.reduce((acc, next) => acc + next.igst, 0)} %</span>
                                </Col>
                                <Col span={16} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0).toFixed(2)}</span>                  
                                </Col>
                            </Row>
                            
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col offset={16} span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <Divider dashed={true} style={{border: '1px dashed #d3d3d3'}}></Divider>
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={10} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Net Amount</span>
                                </Col>
                                <Col span={14} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{formik.values.total_sales_item_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={14} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Old Gold &nbsp;&nbsp;</span>
                                    <span className="saleBillText">{totalOldGoldWeight()} Gm</span>
                                </Col>
                                <Col span={10} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">(-) {formik.values.total_old_gold_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px'}}>
                                <Col offset={16} span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <Divider dashed={true} style={{border: '1px dashed #d3d3d3'}}></Divider>
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={10} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">Grand Total</span>
                                </Col>
                                <Col span={14} style={{display: 'flex', justifyContent: 'end'}}>
                                    <span className="saleBillText">{formik.values.total_sales_amount}</span>                  
                                </Col>
                            </Row>
                            <Row gutter={16} className="gutter-row" style={{paddingTop: '5px', paddingLeft: '20px'}}>
                                <Col span={8} style={{display: 'flex', justifyContent: 'start'}}>
                                    <span className="saleBillText">EST-3</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        );
    };

	return (
		<>
		<PageTitle
			imagesrc={SaleImage}
			pageTitle="Create Sale Entry"
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/sales'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{ createNewFormSection() }		
				</div>
			</div>
            {EstimationReport()}
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
                    {/* <Button
                        icon={<FileImageOutlined />}
						type="primary"
						size="large"
						style={{ width: '150px', backgroundColor: 'green', borderRadius: '4px' }}
						onClick={() => {
                            if(hasValidated(formik.values)) {
                                handlePrint();
                            }                            
                        }}
					>
						Quotation
					</Button> */}
					<Button
                        icon={<ClearOutlined />}
						type="primary"
						size="large"
						style={{ width: '150px', backgroundColor: 'grey', borderRadius: '4px' }}
						onClick={formik.handleReset}
					>
						Reset Values
					</Button>
					<Button
                        icon={<SaveOutlined />}
						type="primary"
						size="large"
						style={{ width: '150px', backgroundColor: '#1f43e5', borderRadius: '4px' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</>
	);


}

export default SalesForm;