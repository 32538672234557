import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tabs, notification, DatePicker, Row, Col } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import moment from 'moment';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const QuotationList = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const [quotationList, setQuotationList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([moment(), moment()]); // Default to current date range

  const tableColumnSearch = columnSearch(searchInput);
  
  useEffect(() => {
    setLoading(true);
    fetchQuotationList();
  }, [dateRange]);

  const fetchQuotationList = () => {
    const [startDate, endDate] = dateRange;
    let url = '/api/sales/quotation/list';
    if (startDate && endDate) {
      url += `?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
    }

    httpCall({
      method: 'GET',
      url: url,
    })
      .then((response) => {
        setLoading(false);
        if (response) {
          setQuotationList(response?.data);
        } else {
          console.error(
            'Error retrieving sales data:',
            response.message
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error retrieving sales data:', error);
      });
  };

  const openNotification = () => {
    api.open({
      message: 'Delete Success !',
      duration: 2,
      description:
        'Sales Data Successfully, Refreshing Grid. ',
      icon: <CheckCircleFilled style={{ color: '#808000' }} />,
    });
  };

  const handleEdit = (id) => {
		navigate('/slj/quotation/form', { state: { id } })
  };

  const handleDelete = (id) => {
    httpCall({
      method: 'DELETE',
			url: '/api/sales/quotation/delete/all?sales_id='+ id,
		}).then(reponse => {
      openNotification();
      fetchQuotationList();
    }).catch(error => {
      console.log('Error>', error)
    })
  };

  const handleDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    } else {
      setDateRange([moment(), moment()]); // Reset to current date range if cleared
    }
  };

  const columnsNew = [
    {
      title: 'Quotation Nr',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
      ...tableColumnSearch('quotation_no'),
      align: 'center',
      width: 150,
      sorter: (a, b) => a.quotation_no.localeCompare(b.quotation_no),
      defaultSortOrder: 'ascend', // Default sorting in ascending order
    },
    {
      title: 'Quotation Date',
      dataIndex: 'quotation_date',
      key: 'quotation_date',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.quotation_no.localeCompare(b.quotation_no),
      defaultSortOrder: 'ascend', // Default sorting in ascending order
      render: (_, record) => {
				return moment(record.quotation_date).format('YYYY-MM-DD')
      }
    },
    {
      title: 'Cost Center Name',
      dataIndex: 'cost_center_name',
      key: 'cost_center_name',
      ...tableColumnSearch('cost_center_name'),
      align: 'center',
      width: 200,
      sorter: (a, b) => a.cost_center_name.localeCompare(b.cost_center_name),
      defaultSortOrder: 'ascend', // Default sorting in ascending order
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      align: 'center',
      width: 200,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      defaultSortOrder: 'ascend', // Default sorting in ascending order
    },
    {
      title: 'Customer Phone Nr',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      align: 'center',
			width: 200
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'center',
      width: 200,
      render: (text) => {
        const formattedAmount = new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(parseFloat(text));
        return formattedAmount;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) =>
        quotationList.length >= 1 ? (
          <Space size="middle">
            <EditOutlined
              style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
              onClick={() => handleEdit(record.quotation_no)}
            />
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => handleDelete(record.quotation_no)}
            >
              <DeleteOutlined
                style={{ fontSize: "20px", color: "#FF6868" }}
              />
            </Popconfirm>
          </Space>
        ) : null
    },
  ];

  const quotationTable = () => {
    return (
      <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
        <Table
          dataSource={quotationList}
          columns={columnsNew}
          rowKey="quotation_no"
						pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
						style={{fontFamily: 'Poppins'}}/>
      </Spin>
		)
	}

  return (
    <>
      <PageTitle
        imagesrc={SalesImage}
        pageTitle="Quotation List"
        buttonList={[{
          buttonName: 'Create Quotation',
          className: 'btn-create',
          url: '/slj/quotation/form'
        }]}
      />
      {contextHolder}
      <div className='vendorListArea'>
        <Row gutter={16}>
          <Col span={5} className="gutter-box">
            <div>
              <span className="hintText color-black">Select Date Range</span>
            </div>
            <RangePicker
              style={{ height: '38px' }}
              onChange={handleDateChange}
              format="DD-MM-YYYY"
              allowClear
            />
          </Col>
        </Row>
        {quotationTable()}
      </div>
    </>
  );
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default QuotationList;