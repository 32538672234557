import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Card, Col, Collapse, DatePicker, Divider, Flex, Input, InputNumber, Row, Select, Space, Statistic, Table, notification, } from "antd";
import { CheckCircleFilled, EditFilled, DeleteFilled, QrcodeOutlined, PlusOutlined, ProfileOutlined, ProfileTwoTone, } from "@ant-design/icons";
import PurchaseImage from '../../assets/images/material-return.svg';
import "../../css/container.css";
import "../../css/SmallComponents.css";
import httpCall from "../../utils/api";
import { useFormik } from "formik";
import PageTitle from "../common/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import ReactToPrint from "react-to-print";
import { QRCodeSVG } from "qrcode.react";

const { Option } = Select;

const PurchaseForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [costCenterList, setCostCenterList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [itemDataList, setItemDataList] = useState([]);
  const [itemDataOptions, setItemDataOptions] = useState([]);
  const [custMobileOptions, setCustMobileOptions] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [purchaseItemList, setPurchaseItemList] = useState([]);
  const [plants, setPlants] = useState([]);
  const [modelType, setModelType] = useState("");
  const [editingItemIndex, setEditingItemIndex] = useState(null);
  const [vendorMobileNumber, setVendorMobileNumber] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [closingWeight, setClosingWeight] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const componentRef = useRef();
  const [qritemDataList, setQrItemDataList] = useState([]);
  const [qritemDataOptions, setQrItemDataOptions] = useState([]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [itemCodeOptions, setItemCodeOptions] = useState([]);
  
  const formik = useFormik({
    initialValues: {
      purchase_order_no: "",
      vendor_id: "",
      sales_person_id: "",
      purchase_order_date: dayjs().format('YYYY-MM-DD'),
      opg_pure: "",
      opg_cash: "",
      reference_no: "",
      bar_rate: "",
      thru: "",
      total_weight: "",
      plant_code: "",
      total_mc_amount: "",
      pending_mc_amount: "",
      closing_weight: ""
    },
    onSubmit: (values, { resetForm }) => {
      if (location?.state?.id) {
        updateSaleData(values);
      } else {
        let errorMsg = "";
        if (values.vendor_id === "") {
          errorMsg = "Please select a vendor by mobile";
        } else if (values.purchase_order_no === "") {
          errorMsg = "Please give purchase order number";
        } else if (values.plant_code === "") {
          errorMsg = "Please select a plant";
        } else if (values.sales_person_id === "") {
          errorMsg = "Please select a sales person";
        } else if (purchaseItemList.length === 0) {
          errorMsg = "Please add a purchase item";
        }
        if (errorMsg === "") {
          createPurchaseData(values, resetForm);
        } else {
          openErrorNotification(errorMsg);
        }
      }
    },
  });

  const purchaseItemFormik = useFormik({
    initialValues: {
      item_id: "",
      item_name: "",
      item_option: {},
      item_code: '',
      item_code_option: {},
      pcs: "",
      weight: "",
      stone_weight: "",
      gross_weight: "",
      igst: "",
      cgst: "",
      sgst: "",
      tax_amount: "",
      stone: "",
      waste: "",
      waste_unit: "",
      huid: "",
      touch: "",
      touch_unit: "%",
      purity_weight: "",
      mc_amount: "",
      other_details: "",
      module: "PURCHASE", // Default module
      sub_module: "ITEM", // Default sub_module
      current_rate: "",
    },
  });

  const itemFormik = useFormik({
    initialValues: {
      item_id: "",
      item_code: "",
      item_name: "",
      weight: "",
      pcs: 1,
      net_weight: "",
      stone_weight: "",
      stone_amount: "",
      waste: "",
      igst: "",
      waste_unit: "",
      huid: "",
      mc_amount: "",
      current_rate: "",
      final_amount: "",
      unique_id: 0,
      total_pcs: 1,
      seq_no:''
    },
    enableReinitialize: true,
  });

  const openErrorNotification = (description) => {
    api.error({
      message: "Error !",
      duration: 4,
      description: description,
    });
  };

  useEffect(() => {
    fetchCostCenter();
    fetchPlantList();
    fetchCustomerList();
    fetchItemDataList();
    if (location?.state?.id) {
      httpCall({
        method: "GET",
        url: "/api/purchase/get?id=" + location?.state?.id,
      })
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleItemChange = (value) => {
    const selectedItem = qritemDataList.find(item => item.itemid === value);
    if (selectedItem) {
      itemFormik.setValues({
        ...itemFormik.values,
        item_name: selectedItem.itemname,
        item_id: selectedItem.itemid,
        current_rate: selectedItem.current_rate || "",
        waste: selectedItem.waste || "",
        waste_unit: selectedItem.waste_unit || "",
        igst: selectedItem.igst || "",
        final_amount: calculateFinalAmount({ ...itemFormik.values, ...selectedItem }), // Dynamically calculate final amount
      });
    }
  };

  const showConfirmModal = () => {
    setIsConfirmModalVisible(true);
  };

  const handleConfirm = async () => {
    await createUnqiueID(); 
    document.getElementById("printTriggerBtnRef").click();
    setIsConfirmModalVisible(false);
  };
  
  // Function to handle cancel action
  const handleCancelConfirm = () => {
    setIsConfirmModalVisible(false);
  };

  const handleManualChange = (fieldName, value) => {
    itemFormik.setFieldValue(fieldName, value);
  };

  const showQrModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  
  useEffect(() => {
    const { item_name, item_id, item_code } = purchaseItemFormik.values;
    if (item_id) {
      handleItemChange(item_id);
    }
    if (item_name) {
      generateUniqueID(item_name);
    }
    if (item_code) {
      itemFormik.setFieldValue("item_code", item_code);
    }
  }, [purchaseItemFormik.values.item_id, purchaseItemFormik.values.item_name,purchaseItemFormik.values.item_code]);
  
  const generateUniqueID = (name) => {
    const uniqueId = name.split(" ").map((n) => n[0]).join("") + dayjs().format("DDMMYY") + "-";
    itemFormik.setFieldValue("unique_id", uniqueId);
  };
  
  const calculateFinalAmount = (values) => {
    const weight = parseFloat(values.weight || 0);
    const stoneWeight = parseFloat(values.stone_weight || 0);
    const stoneAmount = parseFloat(values.stone_amount || 0);
    const waste = parseFloat(values.waste || 0);
    const mcAmount = parseFloat(values.mc_amount || 0);
    const currentRate = parseFloat(values.current_rate || 0);
    const igst = parseFloat(values.igst || 0);

    const netWeight = weight - stoneWeight;

    let wastageAmount = 0;
    if (values.waste_unit === '%') {
      wastageAmount = (netWeight * waste) / 100;
    } else {
      wastageAmount = waste;
    }

    const finalWeight = netWeight + wastageAmount;
    const goldValue = finalWeight * currentRate;
    const subtotal = goldValue + stoneAmount + mcAmount;
    const gstAmount = (subtotal * igst) / 100;
    const total = subtotal + gstAmount;

    return Math.round(total * 100) / 100;
  }

  useEffect(() => {
    const finalAmount = calculateFinalAmount(itemFormik.values);
    itemFormik.setFieldValue('final_amount', finalAmount);
  }, [
    itemFormik.values.weight,
    itemFormik.values.stone_weight,
    itemFormik.values.stone_amount,
    itemFormik.values.waste,
    itemFormik.values.waste_unit,
    itemFormik.values.mc_amount,
    itemFormik.values.current_rate,
    itemFormik.values.igst,
    itemFormik.values.huid
  ]);

  const calculateTotalWeight = () => {
    const totalWeight = purchaseItemList.reduce(
      (acc, next) => acc + parseFloat(next.weight || 0),
      0
    );
    formik.setFieldValue("total_weight", totalWeight.toFixed(3));
    formik.setFieldValue("closing_weight", (closingWeight + totalWeight).toFixed(3))
  };

  const calculateTotalMCAmount = () => {
    const totalMCAmount = purchaseItemList.reduce(
      (acc, next) => acc + parseFloat(next.mc_amount),
      0
    );

    formik.setFieldValue("total_mc_amount", totalMCAmount.toFixed(3));
    formik.setFieldValue("pending_mc_amount", totalMCAmount.toFixed(3));
  };

  useEffect(() => {
    const { weight, current_rate } = purchaseItemFormik.values;
    if (weight && current_rate) {
      calculateTaxAmount(purchaseItemFormik);
    }
  }, [purchaseItemFormik.values.weight, purchaseItemFormik.values.current_rate]);


  // useEffect(() => {
  //   if (
  //     !!purchaseItemFormik.values.weight &&
  //     !!purchaseItemFormik.values.touch
  //   ) {
  //     const weight = parseFloat(purchaseItemFormik.values.weight || 0);
  //     let touch = parseFloat(purchaseItemFormik.values.touch || 0);
  //     const touch_unit = purchaseItemFormik.values.touch_unit;
  //     const pure = touch_unit === "%" ? (weight * touch) / 100 : weight * touch;
  //     purchaseItemFormik.setFieldValue("pure", pure.toFixed(3));
  //   }
  // }, [
  //   purchaseItemFormik.values.weight,
  //   purchaseItemFormik.values.touch,
  //   purchaseItemFormik.values.touch_unit,
  // ]);

  const fetchCostCenter = () => {
    httpCall({
      method: "GET",
      url: "/api/costcenter/get",
    })
      .then((response) => {
        if (response) {
          setCostCenterList(
            response?.data.map((c) => ({
              label: c.cost_center_name,
              value: c.cost_center_id,
            }))
          );
        } else {
          console.error("Error retrieving categories data:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving categories data:", error);
      });
  };

  const fetchPlantList = () => {
    httpCall({
      method: "GET",
      url: "/api/plant/list",
    })
      .then((response) => {
        setPlants(
          response?.data.map((p) => ({
            label: p.plant_name,
            value: p.plant_code,
          }))
        );
      })
      .catch((error) => { });
  };

  const fetchSequenceNo = async (unique_id) => {
    try {
      const response = await httpCall({
        method: "GET",
        url: `/api/item/item_inventory/sequence/${encodeURIComponent(unique_id)}`,
      });
      const sequenceNumber = response.data.lastSequenceNumber;
      itemFormik.setFieldValue("seq_no", sequenceNumber);
      return sequenceNumber;
    } catch (error) {
      console.error("Error retrieving Seq:", error);
      return null;
    }
  };

  const fetchCustomerList = () => {
    httpCall({
      method: "GET",
      url: "/api/vendor/get",
    })
      .then((response) => {
        if (response) {
          setVendorList(response?.data);
          setCustMobileOptions(
            response?.data.map((c) => ({
              label: c.mobile_number,
              value: c.mobile_number,
            }))
          );
        } else {
          console.error("Error retrieving customer list:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving customer list:", error);
      });
  };

  const fetchItemDataList = () => {
    httpCall({
      method: "GET",
      url: "/api/item/sale/list",
    })
      .then((response) => {
        if (response) {
          setItemDataList(response?.data);
          setItemDataOptions(
            response?.data.map((i) => ({
              label: i.itemname,
              value: i.itemname,
            })));
          setQrItemDataList(response?.data);
          setQrItemDataOptions(
            response?.data.map((i) => ({
              label: i.itemname,
              value: i.itemname,
            }))
          );
          setItemCodeOptions(response?.data.map(i => ({
            label: i.item_code,
            value: i.item_code
        })));
        } else {
          console.error("Error retrieving item data list:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error retrieving item data list:", error);
      });
  };

  const openNotification = (description) => {
    api.open({
      message: "Success !",
      duration: 2,
      description: description,
      icon: <CheckCircleFilled style={{ color: "#808000" }} />,
    });
  };

  const createUnqiueID = async () => {
    const uniqueID = itemFormik.values.unique_id;
    const sequenceNumber = await fetchSequenceNo(uniqueID);
    itemFormik.setFieldValue("seq_no", sequenceNumber);
  
    return httpCall({
      method: "POST",
      url: "/api/item/item_inventory/create",
      payload: {
        item_id: itemFormik.values.item_id,
        unique_id: uniqueID,
        pcs: itemFormik.values.total_pcs,
        item_code: itemFormik.values.item_code,
        gross_weight: itemFormik.values.weight,
        stone_weight: itemFormik.values.stone_weight,
        stone_amount: itemFormik.values.stone_amount,
        waste: itemFormik.values.waste,
        igst: itemFormik.values.igst,
        waste_unit: itemFormik.values.waste_unit,
        huid: itemFormik.values.huid,
        mc_amount: itemFormik.values.mc_amount,
        current_rate: itemFormik.values.current_rate,
        final_amount: itemFormik.values.final_amount,
        seq_no: sequenceNumber, // Ensure correct sequence number is used
      },
    }).then((response) => {
      if (response) {
        openNotification("Item Inventory Created Successfully");
      }
    }).catch((error) => {
      console.error("Error from server:", error);
    });
  };
   
  const createPurchaseData = (values, resetForm) => {
    const purchase_order_date = values.purchase_order_date


    const payload = {
      ...values,
      purchase_order_date,
      purchase_items: purchaseItemList.map((item) => ({
        ...item,
        module: "PURCHASE",
        sub_module: "ITEM",
        transaction_ref_no: values.purchase_order_no,
        igst: parseFloat(item.igst || 0),
        cgst: parseFloat(item.cgst || 0),
        sgst: parseFloat(item.sgst || 0),
        tax_amount: item.tax_amount,
        mc_amount: parseFloat(item.mc_amount || 0),
        current_rate: parseFloat(item.current_rate || 0),
        weight: parseFloat(item.weight || 0),
        pcs: parseInt(item.pcs || 0),
        stone: item.stone || "",
        waste: item.waste || "",
        waste_unit: item.waste_unit || "",
        other_details: item.other_details || "",
        touch: parseFloat(item.touch || 0),
        touch_unit: item.touch_unit || "",
        huid: item.huid || "",
        item_code:item.item_code || "",
      })),
      bar_rate: parseFloat(values.bar_rate || 0),
      total_weight: parseFloat(values.total_weight || 0),
      total_mc_amount: parseFloat(values.total_mc_amount || 0),
      pending_mc_amount: parseFloat(values.pending_mc_amount || 0),
    };


    httpCall({
      method: "POST",
      url: "/api/purchase/purchaseOrder",
      payload: payload,
    })
      .then((response) => {
        if (response) {
          openNotification("Purchase Order Created Successfully");
          resetForm();
          setPurchaseItemList([]);
          formik.resetForm();
          purchaseItemFormik.resetForm();
          setVendorMobileNumber(null);
          setSelectedPlant(null);
          setSelectedSalesPerson(null);
        }
        navigate("/slj/purchase");
      })
      .catch((error) => {
        console.error("Error from server:", error);
        openErrorNotification("Purchase Order Number already exists. Please use a different number");
      });
  };

  const updateSaleData = (values) => {
    httpCall({
      method: "PUT",
      url: "/api/purchase/edit",
      payload: { ...values, itemid: location?.state?.id },
    })
      .then((response) => {
        if (response.data.success) {
          openNotification(
            "Purchase Details Updated Successfully, Goto list view. "
          );
          setTimeout(() => navigate("/slj/purchase"), 2500);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = (type, index = null) => {
    setModelType(type);
    setEditingItemIndex(index);
    if (index !== null) {
      const itemToEdit = purchaseItemList[index];
      purchaseItemFormik.setValues(itemToEdit);
    }
  };

  const handleCancel = () => {
    setModelType("");
    setEditingItemIndex(null);
    purchaseItemFormik.resetForm();
  };

  const handleOk = () => {
    const newPurchaseItem = {
      item_id: purchaseItemFormik.values.item_id,
      sales_item_name: purchaseItemFormik.values.sales_item_name,
      unit: purchaseItemFormik.values.unit,
      pcs: purchaseItemFormik.values.pcs,
      weight: purchaseItemFormik.values.weight,
      stone_weight: purchaseItemFormik.values.stone_weight,
      gross_weight: purchaseItemFormik.values.gross_weight,
      current_rate: purchaseItemFormik.values.current_rate,
      mc_amount: purchaseItemFormik.values.mc_amount, // Use manual entry
      huid: purchaseItemFormik.values.huid,
      amount: purchaseItemFormik.values.amount,
      stone: purchaseItemFormik.values.stone,
      waste: purchaseItemFormik.values.waste,
      waste_unit: purchaseItemFormik.values.waste_unit,
      igst: purchaseItemFormik.values.igst,
      sgst: purchaseItemFormik.values.sgst,
      cgst: purchaseItemFormik.values.cgst,
      tax_amount: purchaseItemFormik.values.tax_amount,
      other_details: purchaseItemFormik.values.other_details,
      touch: purchaseItemFormik.values.touch,
      item_code:purchaseItemFormik.values.item_code,
      touch_unit: purchaseItemFormik.values.touch_unit,
    };

    if (newPurchaseItem.sales_item_name && newPurchaseItem.gross_weight && newPurchaseItem.pcs) {
      if (editingItemIndex !== null) {
        updatePurchaseItemList(newPurchaseItem, editingItemIndex);
      } else {
        addPurchaseItem(newPurchaseItem);
      }
      setModelType("");
      setEditingItemIndex(null);
      purchaseItemFormik.resetForm();
    } else {
      openErrorNotification("Please enter required item fields.");
    }
  };

  const updatePurchaseItemList = (updatedItem, index) => {
    setPurchaseItemList((prevList) => {
      const newList = [...prevList];
      newList[index] = updatedItem;
      return newList;
    });
  };

  const addPurchaseItem = (newItem) => {
    setPurchaseItemList((prevList) => [...prevList, newItem]);
  };

  useEffect(() => {
    calculateTotalWeight();
    calculateTotalMCAmount();
  }, [purchaseItemList]);

  const purchaseItemColumns = [
    {
      title: "Item Name",
      dataIndex: "sales_item_name",
      key: "sales_item_name",
      align: "center",
      width: 250,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: 100,
    },
    {
      title: "PCS",
      dataIndex: "pcs",
      key: "pcs",
      align: "center",
      width: 70,
    },
    {
      title: "Weight",
      dataIndex: "gross_weight",
      key: "gross_weight",
      align: "center",
      width: 70,
    },
    {
      title: "Current Rate",
      dataIndex: "current_rate",
      key: "current_rate",
      align: "center",
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 150,
      render: (_, record, index) => (
        <Space size="middle">
          <EditFilled
            onClick={() => showModal("PURCHASE", index)}
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
          />
          <DeleteFilled
            onClick={() => {
              setPurchaseItemList((prevList) =>
                prevList.filter((_, i) => i !== index)
              );
            }}
            style={{ cursor: "pointer", fontSize: "20px ", color: "#B31312" }}
          />
          <QrcodeOutlined
            onClick={showQrModal}
            style={{ cursor: 'pointer', fontSize: '20px', color: 'blue' }}
          />
        </Space>
      ),
    },
  ];


  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const purchaseItemTable = (datasoure) => {
    return datasoure.length > 0 ? (
      <>
        <Row gutter={16} className="gutter-row sales-list">
          <Col span={24} className="gutter-box">
            <Table
              dataSource={datasoure}
              columns={purchaseItemColumns}
              pagination={false}
              rowKey="item_name"
              size="small"
              bordered="true"
              scroll={{ y: 100 }}
              style={{ fontFamily: "Poppins" }}
            />
          </Col>
        </Row>
      </>
    ) : null;
  };

  const updateItemFields = (item_name, form, isReturn) => {
    form.setFieldValue("sales_item_name", item_name);
    const item = itemDataList.find((i) => i.itemname === item_name);
    if (item) {
      form.setFieldValue("item_id", item.itemid);
      form.setFieldValue("item_code",item.item_code)
      form.setFieldValue("product", item.category_name);
      form.setFieldValue("sub_product", item.sub_category_name);
      form.setFieldValue("unit", item.unit);
      form.setFieldValue("waste", parseFloat(item.waste).toFixed(3));
      form.setFieldValue("waste_unit", item.waste_unit);
      form.setFieldValue("stone", parseFloat(item.stone_amount).toFixed(2));
      form.setFieldValue("huid", item.huid);
      form.setFieldValue("sgst", parseFloat(item.sgst).toFixed(3));
      form.setFieldValue("cgst", parseFloat(item.cgst).toFixed(3));
      form.setFieldValue("current_rate", parseFloat(item.current_rate).toFixed(2));

      calculateTaxAmount(form);
    }
  };
  useEffect(() => {
    // Only proceed if pcs has a valid value
    if (purchaseItemFormik.values.pcs === '') {
      return;
    }

    const pcs = parseFloat(purchaseItemFormik.values.pcs) || 1;
    const { gross_weight, stone_weight, current_rate, cgst, sgst, stone, waste, waste_unit } = purchaseItemFormik.values;
    const newGrossWeight = parseFloat(gross_weight) / pcs;
    const newStoneWeight = parseFloat(stone_weight) / pcs;
    const newCgst = parseFloat(cgst) / pcs;
    const newSgst = parseFloat(sgst) / pcs;
    const newStone = parseFloat(stone) / pcs;
    const newWaste = parseFloat(waste) / pcs;

    if (pcs === 1) {
      purchaseItemFormik.setFieldValue('gross_weight', newGrossWeight.toFixed(3));
      purchaseItemFormik.setFieldValue('stone_weight', newStoneWeight.toFixed(3));
      purchaseItemFormik.setFieldValue('cgst', newCgst.toFixed(2));
      purchaseItemFormik.setFieldValue('sgst', newSgst.toFixed(2));
      purchaseItemFormik.setFieldValue('stone', newStone.toFixed(2));
      purchaseItemFormik.setFieldValue('waste', waste_unit === "%" ? waste : (newWaste).toFixed(2));
    } else {
      purchaseItemFormik.setFieldValue('gross_weight', (newGrossWeight * pcs).toFixed(3));
      purchaseItemFormik.setFieldValue('stone_weight', (newStoneWeight * pcs).toFixed(3));
      purchaseItemFormik.setFieldValue('cgst', (newCgst * pcs).toFixed(2));
      purchaseItemFormik.setFieldValue('sgst', (newSgst * pcs).toFixed(2));
      purchaseItemFormik.setFieldValue('stone', (newStone * pcs).toFixed(2));
      purchaseItemFormik.setFieldValue('waste', (waste_unit === "%" ? waste : (newWaste * pcs).toFixed(2)));
    }

    calculateTaxAmount(purchaseItemFormik);
    const weight = parseFloat(purchaseItemFormik.values.weight) || 0;
    purchaseItemFormik.setFieldValue('weight', (weight * pcs).toFixed(3));
  }, [purchaseItemFormik.values.pcs, purchaseItemFormik.values.waste_unit]);

  const handlePcsChange = (e) => {
    const newPcs = e.target.value === '' ? '' : parseInt(e.target.value) || 1;
    const oldPcs = purchaseItemFormik.values.pcs || 1;

    if (newPcs === '') {
      purchaseItemFormik.setFieldValue('pcs', '');

      const item = itemDataList.find((i) => i.itemname === purchaseItemFormik.values.sales_item_name);
      if (item) {
        purchaseItemFormik.setFieldValue('gross_weight', item.gross_weight);
        purchaseItemFormik.setFieldValue('stone_weight', item.stone_weight);
        purchaseItemFormik.setFieldValue('cgst', item.cgst);
        purchaseItemFormik.setFieldValue('sgst', item.sgst);
        purchaseItemFormik.setFieldValue('stone', item.stone_amount);
        purchaseItemFormik.setFieldValue('waste', item.waste);
      }
      return;
    }

    purchaseItemFormik.setFieldValue('pcs', newPcs);

    // Adjust only the waste calculation based on the unit
    const currentWaste = parseFloat(purchaseItemFormik.values.waste) || 0;
    if (purchaseItemFormik.values.waste_unit === "g") {
      const adjustedWaste = currentWaste / oldPcs;
      purchaseItemFormik.setFieldValue('waste', (adjustedWaste * newPcs).toFixed(2));
    } else if (purchaseItemFormik.values.waste_unit === "%") {
      purchaseItemFormik.setFieldValue('waste', currentWaste.toFixed(2));
    }

    ['gross_weight', 'stone_weight', 'stone'].forEach((field) => {
      const value = parseFloat(purchaseItemFormik.values[field]) || 0;
      purchaseItemFormik.setFieldValue(field, (value * newPcs / oldPcs).toFixed(field === 'gross_weight' || field === 'stone_weight' ? 3 : 2));
    });

    calculateTaxAmount(purchaseItemFormik);
  };  
  
  useEffect(() => {
    calculateTaxAmount(purchaseItemFormik);
  }, [purchaseItemFormik.values.sgst, purchaseItemFormik.values.cgst]);

  const calculateTaxAmount = (form) => {
    const weight = parseFloat(form.values.weight) || 0;
    const currentRate = parseFloat(form.values.current_rate) || 0;
    const cgst = parseFloat(form.values.cgst) || 0;
    const sgst = parseFloat(form.values.sgst) || 0;
    const totalTaxPercentage = cgst + sgst;
    const itemValue = weight * currentRate;
    const taxAmount = totalTaxPercentage < 0 ? 0 : (itemValue * totalTaxPercentage) / 100;
    form.setFieldValue("igst", cgst + sgst)
    form.setFieldValue("tax_amount", taxAmount.toFixed(2));
  };


  const wasteTypes = (form) => (
    <Select
      value={form.values.waste_unit}
      style={{ width: 60 }}
      onChange={(e) => form.setFieldValue("waste_unit", e)}
    >
      <Option value="g">g</Option>
      <Option value="%">%</Option>
    </Select>
  );

  const purchaseContainer = () => {
    return (
      <>
        <Row
          gutter={16}
          className="gutter-row"
          justify="center"
          style={{ margin: "0px 0px" }}
        >
          <Col
            span={24}
            className="gutter-box"
            style={{
              textAlign: purchaseItemList.length === 0 ? "center" : "right",
            }}
          >
            <span
              className="saleBillText"
              style={{
                marginRight: "15px",
                fontSize: "13px",
                display: purchaseItemList.length === 0 ? "inline" : "none",
              }}
            >
              No sale item added so far, click here to add
            </span>
          </Col>
        </Row>
        <Row
          gutter={16}
          className="gutter-row"
          justify="center"
          style={{ margin: "0px 0px" }}
        >
          <Col
            span={24}
            className="gutter-box"
            style={{
              textAlign: purchaseItemList.length === 0 ? "center" : "right",
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showModal("PURCHASE")}
              style={{
                width: "50px",
                height: "36px",
                fontFamily: "poppins",
                fontSize: "15px",
              }}
            ></Button>
          </Col>
        </Row>
        {purchaseItemTable(purchaseItemList)}
      </>
    );
  };

  const purchaseOrderForm = () => {
    return (
      <>
        <Row gutter={16} className="gutter-row">
          <Col span={10} className="gutter-box">
            <div>
              <span className="hintText">Vendor Mobile Number</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="mobile_number"
              placeholder="Select Number"
              style={{ width: "100%", height: "38px" }}
              options={custMobileOptions}
              value={vendorMobileNumber}
              onChange={(e) => {
                setVendorMobileNumber(e);
                const vendor = vendorList.find((c) => c.mobile_number === e);
                formik.setFieldValue("vendor_id", vendor.id);
                formik.setFieldValue("name", vendor.company_name);
                formik.setFieldValue("address", vendor.address);
                setClosingWeight(vendor.pending_weight)
              }}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Purchase Date</span>
            </div>
            <DatePicker
              style={{ width: "100%", height: "38px" }}
              disabled={false}
              format="YYYY-MM-DD"
              value={
                formik.values.purchase_order_date
                  ? dayjs(formik.values.purchase_order_date)
                  : null
              }
              onChange={(date, dateString) => {
                formik.setFieldValue("purchase_order_date", dateString);
              }}
              defaultValue={dayjs()} // Use dayjs for default value
            />
          </Col>
          <Col span={4} offset={4} className="gutter-box">
            <div>
              <span className="hintText">Purchase Nr</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              placeholder="Enter Purchase Nr"
              id="purchase_order_no"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values["purchase_order_no"]}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Vendor Name</span>
            </div>
            <Input
              type="text"
              id="name"
              disabled={true}
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Vendor Address</span>
            </div>
            <Input
              type="text"
              id="address"
              disabled={true}
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.address}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Plant</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="plant_code"
              placeholder="Select a Plant"
              style={{ width: "100%", height: "38px" }}
              options={plants}
              value={selectedPlant}
              onChange={(e) => {
                setSelectedPlant(e);
                formik.setFieldValue("plant_code", e);
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={9} className="gutter-box">
            <div>
              <span className="hintText">Sales Person Name</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="sales_person_id"
              placeholder="Select a Name"
              style={{ width: "100%", height: "38px" }}
              options={costCenterList}
              value={selectedSalesPerson}
              onChange={(e) => {
                setSelectedSalesPerson(e);
                formik.setFieldValue("sales_person_id", e);
              }}
            />
          </Col>

          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">OpgPure</span>
            </div>
            <Input
              type="text"
              id="opg_pure"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.opg_pure}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">OpgCash</span>
            </div>
            <Input
              type="text"
              id="opg_cash"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.opg_cash}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Reference No</span>
            </div>
            <Input
              type="text"
              id="reference_no"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.reference_no}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Bar Rate</span>
            </div>
            <Input
              type="text"
              id="bar_rate"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.bar_rate}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Thru</span>
            </div>
            <Input
              type="text"
              id="thru"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.thru}
            />
          </Col>
        </Row>
      </>
    );
  };

  const touchTypes = (form) => (
    <Select
      value={form.values.touch_unit}
      style={{ width: 60 }}
      onChange={(e) => form.setFieldValue("touch_unit", e)}
    >
      <Option value="g">g</Option>
      <Option value="%">%</Option>
    </Select>
  );

  const salesItemTable = (datasoure) => {
    return datasoure.length > 0 ? (
      <>
        <Row gutter={16} className="gutter-row sales-list">
          <Col span={24} className="gutter-box">
            <Table
              dataSource={datasoure}
              columns={purchaseItemColumns}
              pagination={{ position: ["bottomCenter"], pageSize: 2 }}
              rowKey="sales_item_name"
              size="small"
              bordered="true"
              scroll={{ y: 100 }}
              style={{ fontFamily: "Poppins" }}
            />
          </Col>
        </Row>
      </>
    ) : null;
  };

  useEffect(() => {
    const grossWeight = parseFloat(purchaseItemFormik.values.gross_weight) || 0;
    const stoneWeight = parseFloat(purchaseItemFormik.values.stone_weight) || 0;
    const preWeight = grossWeight - (stoneWeight || 0);
    let waste = parseFloat(purchaseItemFormik.values.waste || 0);
    const waste_unit = purchaseItemFormik.values.waste_unit;
    waste = waste_unit === '%' ? (preWeight * waste) / 100 : waste;
    const netWeightWaste = waste < 0 ? preWeight : preWeight - waste;
    const touch = parseFloat(purchaseItemFormik.values.touch) || 0;
    const netWeight = netWeightWaste * (touch / 100 || 1);

    purchaseItemFormik.setFieldValue("weight", netWeight.toFixed(3));
  }, [
    purchaseItemFormik.values.gross_weight,
    purchaseItemFormik.values.stone_weight,
    purchaseItemFormik.values.waste,
    purchaseItemFormik.values.waste_unit,
    purchaseItemFormik.values.touch
  ]);


  const purchaseItemForm = () => {
    return (
      <>
        <Row gutter={16} className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Item Code</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="item_code"
              showSearch
              placeholder="Select an Item Code"
              style={{ width: "100%", height: "38px" }}
              options={itemCodeOptions}
              value={purchaseItemFormik.values.item_code}
              onChange={(value, option) => {
                const selectedItem = itemDataList.find(item => item.item_code === value);
                if (selectedItem) {
                  updateItemFields(selectedItem.itemname, purchaseItemFormik, false);
                  generateUniqueID(selectedItem.itemname)
                }
                purchaseItemFormik.setFieldValue("item_code", value);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              virtual={false}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Item Name</span>
              <span className="mandatory"></span>
            </div>
            <Select
              id="item_name"
              placeholder="Select an Item"
              style={{ width: "100%", height: "38px" }}
              options={itemDataOptions}
              value={purchaseItemFormik.values.sales_item_name}
              disabled={true}
              onChange={(value) => {
                purchaseItemFormik.setFieldValue('item_name', value);
                purchaseItemFormik.setFieldValue('sales_item_name', value);
                purchaseItemFormik.setFieldValue('pcs', 1)
                updateItemFields(value, purchaseItemFormik, false);
              }}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">Unit</span>
            </div>
            <Input
              type="text"
              id="unit"
              disabled={true}
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.unit}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">PCS</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="number"
              id="pcs"
              style={{ width: "100%" }}
              onChange={handlePcsChange}
              value={purchaseItemFormik.values.pcs}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">Gross Weight</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="gross_weight"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              // value={purchaseItemFormik.values.gross_weight}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">Stone Weight</span>
            </div>
            <Input
              type="text"
              id="stone_weight"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              // value={purchaseItemFormik.values.stone_weight}
            />
          </Col>
          <Col span={10} className="gutter-box">
            <Space.Compact style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <div>
                  <span className="hintText">Net Weight</span>
                  <span className="mandatory"></span>
                </div>
                <Input
                  type="number"
                  id="weight"
                  disabled={true}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    purchaseItemFormik.handleChange(e);
                    calculateTaxAmount(purchaseItemFormik);
                  }}
                  value={purchaseItemFormik.values.weight}
                />
              </div>
              <div style={{ width: "50%" }}>
                <div>
                  <span className="hintText">Current Market Rate</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  id="current_rate"
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  value={purchaseItemFormik.values.current_rate}
                />
              </div>
            </Space.Compact>
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">CGST (%)</span>
            </div>
            <Input
              type="text"
              id="cgst"
              disabled={true}
              style={{ width: "100%", color: "#000000" }}
              value={purchaseItemFormik.values.cgst}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">SGST (%)</span>
            </div>
            <Input
              type="text"
              id="sgst"
              disabled={true}
              style={{ width: "100%", color: "#000000" }}
              value={purchaseItemFormik.values.sgst}
            />
          </Col>

        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">Stone Amount</span>
            </div>
            <Input
              type="text"
              id="stone"
              style={{ width: "100%", color: "#000000" }}
              // value={purchaseItemFormik.values.stone}
            />
          </Col>
          <Col span={5} className="gutter-box">
            <div>
              <span className="hintText">Waste</span>
            </div>
            <Input
              type="text"
              style={{ width: "100%", color: "#000000" }}
              addonAfter={wasteTypes(purchaseItemFormik)}
              id="waste"
              value={purchaseItemFormik.values.waste}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">Touch (%)</span>
            </div>
            <Input
              type="text"
              id="touch"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.touch}
            />
          </Col>
          <Col span={4} className="gutter-box">
            <div>
              <span className="hintText">MC Amount</span>
            </div>
            <Input
              type="number"
              id="mc_amount"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.mc_amount}
            />
          </Col>
          <Col span={3} className="gutter-box">
            <div>
              <span className="hintText">HUID</span>
            </div>
            <Input
              type="text"
              id="huid"
              style={{ width: "100%" }}
              // value={purchaseItemFormik.values.huid}
            />
          </Col>
          <Col span={5} className="gutter-box">
            <div>
              <span className="hintText">Tax Amount</span>
            </div>
            <Input
              type="number"
              id="tax_amount"
              style={{ width: "100%", color: "#000000" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.tax_amount}
              disabled={true}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Other Details</span>
            </div>
            <Input
              type="text"
              id="other_details"
              style={{ width: "100%" }}
              onChange={purchaseItemFormik.handleChange}
              value={purchaseItemFormik.values.other_details}
            />
          </Col>
        </Row>
      </>
    );
  };

  const createNewFormSection = () => {
    return (
      <>
        {purchaseOrderForm()}
        <Divider orientation="left" orientationMargin="0">
          Purchase
        </Divider>
        {purchaseContainer()}
        <Card style={{ marginTop: "35px" }}>
          <Row gutter={16} className="gutter-row">
            <Col
              span={8}
              offset={16}
              className="gutter-box"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Statistic
                title="Total Weight (g)"
                value={formik.values.total_weight}
                precision={3}
                style={{
                  textAlign: "right",
                  border: "1px dotted #d3d3d3",
                  padding: "10px",
                }}
                valueStyle={{
                  width: "275px",
                  fontSize: "28px",
                  textAlign: "right",
                  color: "#000000",
                  fontWeight: "bolder",
                }}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  };

  const qrCodeContainer = () => {
    const grossWeight = parseFloat(itemFormik.values.weight || 0);
    const stoneWeight = parseFloat(itemFormik.values.stone_weight || 0);
    const netWeight = grossWeight - stoneWeight;

    const qrData = JSON.stringify({
      item_id: itemFormik.values.item_id,
      item_code: itemFormik.values.item_code,
      item_name: itemFormik.values.item_name,
      gross_weight: grossWeight.toFixed(3),
      unique_id: `${itemFormik.values.unique_id}-${itemFormik.values.seq_no}`, // Use a colon here
      net_weight: netWeight.toFixed(3),
      stone_weight: stoneWeight.toFixed(3),
      stone_amount: itemFormik.values.stone_amount,
      waste: itemFormik.values.waste,
      waste_unit: itemFormik.values.waste_unit,
      huid: itemFormik.values.huid,
      mc_amount: itemFormik.values.mc_amount,
      final_amount: Math.round(itemFormik.values.final_amount)
    });
    

    return (
      <>
        <div style={{ display: "none" }}>
          <div
            ref={componentRef}
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "3px",
              gap: "55px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "7px",
                fontFamily: "Tahoma",
                fontWeight: "700",
              }}
            >
              <span>Gr.wt: {grossWeight.toFixed(3)} g</span>
              <span>Nt.wt: {netWeight.toFixed(3)} g</span>
              <span>St.Amt: ₹{itemFormik.values.stone_amount}</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
              }}
            >
              <QRCodeSVG value={qrData} size={30} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "6px",
                  marginLeft: "5px",
                  fontFamily: "Tahoma",
                  fontWeight: "700",
                }}
              >
                <span>
                  {itemFormik.values.item_code}
                </span>
                    <span>{`${itemFormik.values.unique_id}-${itemFormik.values.seq_no}`}</span>
                    <span>{itemFormik.values.huid}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderForm = () => (
    <div style={{ width: "70%", display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Row gutter={16} className="gutter-row flex-ai-center">
      <Col span={6}>
          <div>
            <span className="lbl-black">Item Code</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Select
            id="item_code"
            placeholder="Select an Item code"
            disabled={true}
            style={{ width: "100%", height: "38px" }}
            options={itemCodeOptions}
            value={itemFormik.values.item_code}
            onChange={handleItemChange}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">Item Name</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Select
            id="item_name"
            placeholder="Select an Item"
            disabled={true}
            style={{ width: "100%", height: "38px" }}
            options={qritemDataOptions}
            value={itemFormik.values.item_name}
            onChange={handleItemChange}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">PCS</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="text"
            id="pcs"
            disabled={true}
            style={{ width: "100%", color: "#000000" }}
            value={itemFormik.values.pcs}
            onChange={(e) => handleManualChange("pcs", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Gross Weight</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="weight"
            suffix="g"
            style={{ width: "100%" }}
            value={itemFormik.values.weight}
            onChange={(e) => handleManualChange("weight", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Stone Weight</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="stone_weight"
            suffix="g"
            style={{ width: "100%" }}
            value={itemFormik.values.stone_weight}
            onChange={(e) => handleManualChange("stone_weight", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">Stone Amount</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="text"
            id="stone_amount"
            prefix="₹"
            style={{ width: "100%", color: "#000000" }}
            value={itemFormik.values.stone_amount}
            onChange={(e) => handleManualChange("stone_amount", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Net Weight</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            disabled
            type="number"
            suffix="g"
            id="net_weight"
            style={{ width: "100%" }}
            value={parseFloat(itemFormik.values.weight || 0) - parseFloat(itemFormik.values.stone_weight || 0)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Wastage</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="text"
            style={{ width: "100%", color: "#000000" }}
            addonAfter={wasteTypes(itemFormik)}
            id="waste"
            value={itemFormik.values.waste}
            onChange={(e) => handleManualChange("waste", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">GST (SGST + CGST)</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="igst"
            style={{ width: "100%" }}
            suffix="%"
            value={itemFormik.values.igst}
            onChange={(e) => handleManualChange("igst", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">MC Amount</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="number"
            id="mc_amount"
            prefix="₹"
            style={{ width: "100%" }}
            value={itemFormik.values.mc_amount}
            onChange={(e) => handleManualChange("mc_amount", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <div>
            <span className="lbl-black">HUID</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            type="text"
            style={{ width: "100%", color: "#000000" }}
            id="huid"
            value={itemFormik.values.huid}
            onChange={(e) => handleManualChange("huid", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row flex-ai-center">
        <Col span={6}>
          <div>
            <span className="lbl-black">Final Amount</span>
            <span className="mandatory"></span>
          </div>
        </Col>
        <Col span={6}>
          <Input
            disabled
            type="number"
            prefix="₹"
            id="final_amount"
            style={{ width: "100%", color: "#000000", padding: '7px 11px' }}
            value={Math.round(itemFormik.values.final_amount)}
          />
        </Col>
      </Row>
      <Row gutter={16} className="gutter-row">
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space style={{ marginTop: "20px" }}>
            <ReactToPrint
              trigger={() => <Button id="printTriggerBtnRef" style={{ display: "none" }} />}
              content={() => componentRef.current}
            />
            <Button
              icon={<QrcodeOutlined />}
              type="primary"
              size="large"
              style={{ width: '150px', backgroundColor: 'green', borderRadius: '4px', marginLeft: '12%' }}
              onClick={showConfirmModal} // Updated to show the confirm modal
            >
              Generate QR
            </Button>
            <Modal
              title="Confirm Print"
              visible={isConfirmModalVisible}
              onOk={handleConfirm}
              onCancel={handleCancelConfirm}
              okText="Yes"
              cancelText="Cancel"
            >
              <p>Are you sure you want to print the QR code?</p>
            </Modal>
          </Space>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <PageTitle
        imagesrc={PurchaseImage}
        pageTitle="Create Purchase Entry"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/purchase",
          },
        ]}
      />
      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">{createNewFormSection()}</div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "grey" }}
            onClick={() => {
              formik.resetForm();
              setPurchaseItemList([]);
              purchaseItemFormik.resetForm();
            }}
          >
            Reset Fields
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "#1f43e5" }}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <Modal
        title={
          editingItemIndex !== null ? "Edit Purchase Item" : "Add Purchase Item"
        }
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={modelType === "PURCHASE"}
        onOk={handleOk}
        width={"800px"}
        onCancel={handleCancel}
      >
        <div>{purchaseItemForm()}</div>
      </Modal>
      <Modal
        title="QR Code Generator"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width="70%"
      >
        <div className="content-area" style={{ overflow: 'hidden' }}>
          <div className="formAreaStyle" style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
            {renderForm()}
            {qrCodeContainer()}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseForm;
