import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Spin, Pagination, Input, Button, Table, notification, Modal } from 'antd';
import PageTitle from '../common/PageTitle';
import inventoryImage from '../../assets/images/inventory_T.svg';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { EyeOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const StockList = () => {
    const [stockList, setStockList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItemDetails, setSelectedItemDetails] = useState(null);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        setLoading(true);
        fetchStockList();
    }, []);

    const fetchStockList = () => {
        httpCall({
            method: 'GET',
            url: '/api/stock/list',
        })
            .then((response) => {
                setLoading(false);
                setStockList(response?.data);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching stock list:', error);
            });
    };

    const fetchItemDetails = (item_id) => {
        setLoading(true);
        httpCall({
            method: 'GET',
            url: `/api/stock/list/item/inventory?item_id=${item_id}`,
        })
            .then((response) => {
                setLoading(false);
                setSelectedItemDetails(response?.data);
                setIsModalVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching item details:', error);
            });
    };

    const filteredItems = selectedItemDetails?.filter(item =>
        item?.item_details_combined?.toLowerCase().includes(filterText.toLowerCase())
    ) || [];

    const exportToExcel = () => {
        if (!filteredItems.length) return;
        const data = filteredItems.map(item => ({
            'Product QR': item.item_details_combined || 'N/A',
            'HUID': item.huid || 'N/A',
            'Gross Weight (g)': parseFloat(item.gross_weight || 0).toFixed(3),
            'Stone Weight (g)': parseFloat(item.stone_weight || 0).toFixed(3),
            'Net Weight (g)': (parseFloat(item.gross_weight || 0) - parseFloat(item.stone_weight || 0)).toFixed(3),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);

        // Adjust column widths
        const colWidths = [
            { wch: 25 },
            { wch: 15 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 }
        ];
        worksheet['!cols'] = colWidths;

        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "4472C4" } }
        };

        ['A1', 'B1', 'C1', 'D1', 'E1'].forEach(cell => {
            if (worksheet[cell]) worksheet[cell].s = headerStyle;
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Details');
        XLSX.writeFile(workbook, 'Stock_Details.xlsx');
    };

    const columnsNew = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 250,
            sorter: (a, b) => a.itemname.localeCompare(b.itemname),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Opening PCS',
            dataIndex: 'opg_pcs',
            key: 'opg_pcs',
            align: 'center',
            width: 180,
            sorter: (a, b) => Number(a.opg_pcs) - Number(b.opg_pcs),
        },
        {
            title: 'Opening Weight (g)',
            dataIndex: 'opg_weight',
            key: 'opg_weight',
            align: 'center',
            width: 180,
            sorter: (a, b) => Number(a.opg_weight) - Number(b.opg_weight),
            render: (value) => {
                return value ? value.toFixed(3) : '0.000';
            }
        },
        {
            title: 'Total PCS',
            key: 'pcs',
            align: 'center',
            width: 180,
            sorter: (a, b) => (Number(a.opg_pcs) + Number(a.pcs)) - (Number(b.opg_pcs) + Number(b.pcs)),
            render: (_, record) => {
                const totalPcs = (Number(record.opg_pcs) || 0) + (Number(record.pcs) || 0);
                return totalPcs.toLocaleString();
            }
        },
        {
            title: 'Total Weight (g)',
            key: 'weight',
            align: 'center',
            width: 180,
            sorter: (a, b) => (Number(a.opg_weight) + Number(a.weight)) - (Number(b.opg_weight) + Number(b.weight)),
            render: (_, record) => {
                const totalWeight = (Number(record.opg_weight) || 0) + (Number(record.weight) || 0);
                return totalWeight.toFixed(3);
            }
        },
        {
            title: 'View Details',
            key: 'viewDetails',
            align: 'center',
            render: (_, record) => (
                <Space>
                    <EyeOutlined
                        style={{ cursor: 'pointer', color: '#1890ff', fontSize: '16px' }}
                        onClick={() => {
                            fetchItemDetails(record.itemid);
                        }}
                    />
                </Space>
            ),
        },
    ];

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedItemDetails(null);
        setFilterText('');
    };

    const summary = (pageData) => {
        let totalOpnPcs = 0;
        let totalOpnWeight = 0;
        let totalPcs = 0;
        let totalWeight = 0;

        pageData.forEach(({ opg_pcs, opg_weight, pcs, weight }) => {
            totalOpnPcs += (Number(opg_pcs) || 0);
            totalPcs += (Number(pcs) || 0);
            totalOpnWeight += (Number(opg_weight) || 0);
            totalWeight += (Number(weight) || 0);
        });

        const totalCombinedPcs = totalOpnPcs + totalPcs;
        const totalCombinedWeight = totalOpnWeight + totalWeight;
        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                        <strong>Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                        <strong>{totalOpnPcs.toLocaleString()}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                        <strong>{totalOpnWeight.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                        <strong>{totalCombinedPcs.toLocaleString()}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                        <strong>{totalCombinedWeight.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={1} />
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    const renderItemTable = (items) => {
        return (
            <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>Product QR</th>
                            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>HUID</th>
                            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>Gross Weight</th>
                            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>Stone Weight</th>
                            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>Net Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                                    {item.item_details_combined || 'N/A'}
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                                    {item.huid || 'N/A'}
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                                    {item.gross_weight || 'N/A'}
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                                    {item.stone_weight || 'N/A'}
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                                    {parseFloat(item.gross_weight || 0) - parseFloat(item.stone_weight || 0)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            <PageTitle
                imagesrc={inventoryImage}
                pageTitle="Stock List"
                buttonList={[]}
            />
            {contextHolder}
            <div className='vendorListArea'>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Table
                        dataSource={stockList}
                        columns={columnsNew}
                        rowKey="item_id"
                        pagination={{
                            showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['10', '20', '30']
                        }}
                        scroll={{ y: 570 }}
                        style={{ fontFamily: 'Poppins' }}
                        summary={summary}
                    />
                </Spin>
            </div>
            <Modal
                title="Piece Details"
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                width="75%"
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                    <div>
                        <strong>Item Name:</strong> {filteredItems[0]?.itemname || 'N/A'}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input.Search
                            placeholder="Search items..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            onSearch={(value) => setFilterText(value)}
                            style={{
                                width: '250px',
                            }}
                            allowClear
                        />
                    </div>
                </div>

                <p><strong>Item Type:</strong> {filteredItems[0]?.item_type || 'N/A'}</p>
                <p style={{ textAlign: 'right' }}>
                    <Button type="primary" icon={<FileExcelOutlined />} onClick={exportToExcel}>
                        Export to Excel
                    </Button>
                </p>
                <p style={{ textAlign: 'right' }}>
                    <strong>Total Pieces: {filteredItems.length}</strong>
                </p>

                {filteredItems.length > 0 ? (
                    renderItemTable(filteredItems)
                ) : (
                    <p>No pieces available for this item.</p>
                )}
            </Modal>
        </>
    );
};

export default StockList;