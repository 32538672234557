import React from 'react';
import '../../css/PageTitle.css';
import { Button } from 'antd';
import { LeftOutlined, PlusOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const PageTitle = ({ imagesrc, pageTitle, buttonList }) => {
  const navigate = useNavigate();
  return (
    <div className="pageTitle">
      <img className="pageTitleImage" alt="Page Title" src={imagesrc}></img>
      <span className="pageTitleText">{pageTitle}</span>
      <div className="buttonList">
        {buttonList?.length > 0
          ? // eslint-disable-next-line
            buttonList.map((button, i) => {
              return button.className === 'btn-qr' ? (
                <Button
                  key={i}
                  color="default"
                  variant="outlined"
                  icon={<QrcodeOutlined />}
                  onClick={() => {
                    navigate(button.url)
                  }}
                >
                  {button.buttonName}
                </Button>
              ) : (
                <Button
                  key={i}
                  type={button.buttonName === 'Back' ? 'link' : 'primary'}
                  shape={button.buttonName === 'Back' ? 'circle' : 'null'}
                  icon={button.buttonName === 'Back' ? <LeftOutlined /> : <PlusOutlined />}
                  onClick={() => {
                    navigate(button.url)
                  }}
                >
                  {button.buttonName}
                </Button>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default React.memo(PageTitle);
