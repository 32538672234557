import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Row, Select, Spin, Table, Card } from 'antd';
import PageTitle from '../common/PageTitle';
import salesreportImage from '../../assets/images/sales_report.svg';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SaleReport = () => {
    const navigate = useNavigate();
    const [salesOrderList, setSalesOrderList] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [netSalesAmount, setNetSalesAmount] = useState(0);
    const [totalSalesAmount, setTotalSalesAmount] = useState(0);
    const [costCenterData, setCostCenterData] = useState([]);
    
    useEffect(() => {
        setLoading(true);
        fetchCustomerList();
        fetchCostCenters();
    }, []);

    const formik = useFormik({
      initialValues: {
        customer_id: '',
        from_date: '',
        to_date: '',
        cost_center_id: ''
      },
      onSubmit: () => {},
    });
    

    useEffect(() => {
        if(formik.values.customer_id) {
        setLoading(true);
        Promise.all([
          fetchSalesOrderList(formik.values.customer_id, formik.values.cost_center_id),
        ]).then(() => {
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        });
      } else {
        setSalesOrderList([]);
        setReportData([]);
        setNetSalesAmount(0);
        setTotalSalesAmount(0);
      }
    }, [formik.values.customer_id, formik.values.cost_center_id]);
    
    useEffect(() => {
        let combinedData = [...salesOrderList];
        
        if (formik.values.from_date && formik.values.to_date) {
            const startDate = moment(formik.values.from_date).startOf('day');
            const endDate = moment(formik.values.to_date).endOf('day');
            
            combinedData = combinedData.filter(item => {
                const itemDate = moment(item.date);
                return itemDate.isBetween(startDate, endDate, 'day', '[]');
            });
        }

        const salesAmounts = salesOrderList
            .filter(item => formik.values.from_date && formik.values.to_date 
                ? moment(item.date).isBetween(
                    moment(formik.values.from_date).startOf('day'), 
                    moment(formik.values.to_date).endOf('day'), 
                    'day', 
                    '[]'
                ) 
                : true
            );

        const totalSales = salesAmounts.reduce((sum, item) => sum + (item.amount || 0), 0);

        const enhancedData = combinedData.map(item => {
            const isSalesOrder = salesOrderList.includes(item);
            return {
                ...item,
                sales_amount: isSalesOrder ? (item.amount || 0) : 0,            };
        });

        setNetSalesAmount(totalSales);
        setTotalSalesAmount(totalSales);
        setReportData(enhancedData);
    }, [salesOrderList, formik.values.from_date, formik.values.to_date]);
    
    const fetchSalesOrderList = (customer_id, cost_center_id) => {
      const url = cost_center_id 
        ? `/api/sales/order/report/${customer_id}/${cost_center_id}` 
        : `/api/sales/order/report/${customer_id}`;
      return httpCall({
        method: 'GET',
        url,
      })
      .then((response) => {
        setSalesOrderList(response?.data || []);
      })
      .catch((error) => {
        console.error('Error retrieving sales order data:', error);
        setSalesOrderList([]);
      });
    };
    
    const fetchCostCenters = async () => {
      try {
          const response = await httpCall({
              method: 'GET',
              url: '/api/costcenter/get',
          });
          if (response && response.data) {
              setCostCenterData(response?.data.map(cc => ({label: cc.cost_center_name, value: cc.cost_center_id})));
          } else {
              setCostCenterData([]);
              console.error('Error retrieving cost center data: Unexpected response format', response);
          }
      } catch (error) {
          setCostCenterData([]);
          console.error('Error fetching cost center data:', error);
      }
  };

    const fetchCustomerList = () => {
        httpCall({
            method: 'GET',
            url: '/api/customer/get',
        })
            .then((response) => {
                if (response) {
                    setCustomerList(response?.data.map(c => ({label: c.company_name, value: c.id})));                    
                } else {
                    console.error(
                        'Error retrieving customer list:',
                        response.message
                    );
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error retrieving customer list:', error);
                setLoading(false);
            });
    };

    const handleDateChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            formik.setFieldValue('from_date', start.format('YYYY-MM-DD'));
            formik.setFieldValue('to_date', end.format('YYYY-MM-DD'));
        } else {
            formik.setFieldValue('from_date', '');
            formik.setFieldValue('to_date', '');
        }
    };

    const columnsNew = [
      {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        width: 150,
        sorter: (a, b) => moment(a.date) - moment(b.date),
            defaultSortOrder: 'ascend',
        render: (_, record) => {
                return moment(record.date).format('DD-MM-YYYY');
            }
      },
      {
            title: 'Particulars',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
            width: 300
      },
      {
            title: 'Vch Type',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
        width: 75,
        render: (_, record) => {
          return (
                    <span style={{fontSize: '13px', fontWeight: '700'}}>
              {record.vch_type.toUpperCase()}
            </span>
                )
            }
      },
      {
            title: 'Vch No',
            dataIndex: 'vch_no',
            key: 'vch_no',
            align: 'center',
            width: 100
      },
      {
        title: "Sales Amount",
        dataIndex: "sales_amount",
        key: "sales_amount",
        align: "center",
        width: 100,
        render: (text) => {
          const numericValue = parseFloat(text);
          if (numericValue > 0) {
            const formattedAmount = new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(numericValue);
            return formattedAmount;
          }
          return "";
        },
      },
    ];

    const mergedColumns = React.useMemo(() => {
        return columnsNew.map((col) => ({
            ...col,
            onCell: undefined,
        }));
    }, []);

	return (
    <>
      <PageTitle
        imagesrc={salesreportImage}
        pageTitle="Sales Report"
        buttonList={[]}
      />
      <div className="vendorListArea">
        <Row gutter={12} className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Customer</span>
            </div>
            <Select
              id="customer_id"
              showSearch
              placeholder="Select Customer"
                            style={{ width: '100%', height: '38px' }}
              options={customerList}
                            onChange={(e)=> {
                formik.setFieldValue("customer_id", e);
              }}
              allowClear
            />
          </Col>
          <Col span={5} className="gutter-box">
            <div>
              <span className="hintText color-black">Select Date Range</span>
            </div>
            <RangePicker
                            style={{height: '38px'}} 
              onChange={handleDateChange}
              format="DD-MM-YYYY"
              allowClear
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText color-black">Managed By</span>
            </div>
            <Select
              id="cost_center_id"
              showSearch
              placeholder="Select Manager"
              style={{ width: "100%", height: "38px" }}
              options={costCenterData}
              onChange={(value) =>
                formik.setFieldValue("cost_center_id", value)
              }
              allowClear
            />
          </Col>
        </Row>

                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
          <Table
            dataSource={reportData}
                        size='small'
            bordered={true}
            columns={columnsNew}
            rowKey="vch_no"
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={4} align="right">
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <strong>
                      ₹
                      {new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(totalSalesAmount)}
                    </strong>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
               </Table.Summary>
            )}
            pagination={{
              showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['10', '20', '30'],
              showTotal: (total, range) => (
                <div style={{ fontFamily: "Poppins", fontSize: "15px" }}>
                  <strong>
                    {new Intl.NumberFormat("en-IN").format(range[0])}
                  </strong>{" "}
                  to{" "}
                  <strong>
                    {new Intl.NumberFormat("en-IN").format(range[1])}
                  </strong>{" "}
                  of{" "}
                  <strong>
                    {new Intl.NumberFormat("en-IN").format(total)}
                  </strong>{" "}
                  records
                </div>
                            )
            }}
                        style={{fontFamily: 'Poppins'}}
          />
        </Spin>
      </div>
    </>
  );
};

export default SaleReport;