import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Table, notification, Radio, Row, Col } from 'antd';
import PageTitle from '../common/PageTitle';
import CostCenterImage from '../../assets/images/vendor.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';

const AbstractReport = () => {
    const navigate = useNavigate();
    const searchInputVendor = useRef(null);
    const searchInputCustomer = useRef(null);
    const [vendorList, setVendorList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const tableColumnSearchVendor = columnSearch(searchInputVendor);
    const tableColumnSearchCustomer = columnSearch(searchInputCustomer);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState('vendor'); 

    useEffect(() => {
        setLoading(true);
        fetchVendorList();
        fetchCustomerList();
    }, []);

    const fetchVendorList = () => {
        httpCall({
            method: 'GET',
            url: '/api/vendor/get',
        })
            .then((response) => {
                setLoading(false);
                if (response) {
                    setVendorList(response.data.map(vendor => ({
                        ...vendor,
                        pending_weight: vendor.pending_weight || 0,
                    })));
                } else {
                    console.error('Error retrieving vendor data:', response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving vendor data:', error);
            });
    };

    const fetchCustomerList = () => {
        httpCall({
            method: 'GET',
            url: '/api/customer/get',
        })
            .then((response) => {
                setLoading(false);
                if (response) {
                    setCustomerList(response.data.map(customer => ({
                        ...customer,
                        pending_amount: customer.pending_amount || 0,
                    })));
                } else {
                    console.error('Error retrieving customer data:', response.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving customer data:', error);
            });
    };

    const resetFilters = () => {
        if (searchInputVendor.current) {
            searchInputVendor.current.state.value = '';
        }
        if (searchInputCustomer.current) {
            searchInputCustomer.current.state.value = '';
        }
    };

    const handleViewChange = (e) => {
        resetFilters();
        setView(e.target.value);
    };

    const columnsVendor = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            ...tableColumnSearchVendor('company_name'),
            align: 'center',
            width: 300,
            sorter: (a, b) => a.company_name.localeCompare(b.company_name),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            ...tableColumnSearchVendor('mobile_number'),
            align: 'center',
            width: 200,
        },
        {
            title: 'Pending Weight',
            dataIndex: 'pending_weight',
            key: 'pending_weight',
            align: 'center',
            width: 200,
            render: (text) => `${text}g`
        },
    ];

    const columnsCustomer = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            ...tableColumnSearchCustomer('company_name'),
            align: 'center',
            width: 300,
            sorter: (a, b) => (a.company_name ?? '').localeCompare(b.company_name ?? ''),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            ...tableColumnSearchCustomer('mobile_number'),
            align: 'center',
            width: 200,
        },
        {
            title: 'Pending Amount',
            dataIndex: 'pending_amount',
            key: 'pending_amount',
            align: 'center',
            width: 200,
            render: (text) => `₹${new Intl.NumberFormat('en-IN').format(text)}` // Add Indian Rupee symbol and comma separators
        },
    ];

    return (
        <>
            <PageTitle
                imagesrc={CostCenterImage}
                pageTitle={view === 'vendor' ? 'Vendor List' : 'Customer List'} // Update title based on view
            />
            {contextHolder}
            <div className="vendorListArea">
                <Row justify="center" style={{ marginBottom: '16px' }}>
                    <Col>
                        <Radio.Group value={view} onChange={handleViewChange}>
                            <Radio.Button value="vendor">Vendor</Radio.Button>
                            <Radio.Button value="customer">Customer</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    {view === 'vendor' ? (
                        <Table
                            dataSource={vendorList}
                            columns={columnsVendor}
                            rowKey="id"
                            pagination={{ showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal }}
                            style={{ fontFamily: 'Poppins' }}
                        />
                    ) : (
                        <Table
                            dataSource={customerList}
                            columns={columnsCustomer}
                            rowKey="id"
                            pagination={{ showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal }}
                            style={{ fontFamily: 'Poppins', marginTop: '50px' }}
                        />
                    )}
                </Spin>
            </div>
        </>
    );
};

const handleTotal = (total, range) => (
    <div style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
        <strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records
    </div>
);

export default AbstractReport;