import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/plant.svg';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';

const PlantList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [plantList, setPlantList] = useState([]);	
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		fetchPlantList();
	}, []);

	const fetchPlantList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
		.then((response) => {
			setLoading(false);
			setPlantList(response.data);
		})
		.catch((error) => {
			setLoading(false);
			console.error('Error fetching plant list:', error);
		});
	};
	

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Plant Deleted Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/plant/form', { state: { id } });
	};
	

	const handleDelete = (plant_code) => {
		httpCall({
			method: 'DELETE',
			url: '/api/plant/delete/'+ plant_code,
		}).then(reponse => {
			openNotification();
			fetchPlantList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
        {
			title: 'Plant Code',
			dataIndex: 'plant_code',
			key: 'plant_code',
			align: 'center',
			width: 150,
			sorter: (a, b) => a.plant_code.localeCompare(b.plant_code),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Plant Name',
			dataIndex: 'plant_name',
			key: 'plant_name',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.plant_name.localeCompare(b.plant_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			align: 'center',
			width: 200
		},
		{
			title: 'Phone Nr',
			dataIndex: 'phone_no',
			key: 'mobile_number',
			align: 'center',
			width: 200
		},
        {
			title: 'GST No',
			dataIndex: 'gst_no',
			key: 'gst_no',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.gst_no.localeCompare(b.gst_no),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Account',
			dataIndex: 'account_no',
			key: 'account_no',
			align: 'center',
			width: 200,
			sorter: (a, b) => a.account_no.localeCompare(b.account_no),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				plantList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.plant_code)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.plant_code)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	return (
		<>
			<PageTitle
				imagesrc={SalesImage}
				pageTitle="Plant List"
				buttonList={[{
					buttonName: 'Create Plant',
					className: 'btn-create',
					url: '/slj/plant/form'
				}]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
				<Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
						<Table 
							dataSource={plantList}
							columns={columnsNew}
							rowKey="plant_code"
							pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
							style={{fontFamily: 'Poppins'}}/>
				</Spin>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default PlantList;
