import React, { useState } from 'react';
import '../../css/Components.css';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import ImageLogo from '../../assets/images/enterot-logo.png';

import image from '../../assets/images/profile.svg';

const Banner = () => {
  const responseData = {username: 'Anand', role: 'Admin'}
  const isMenuCollapsed = false;
  const [logoutPopover, setLogoutPopover] = useState(false);
  const Navigate = useNavigate();
  const logout = () => {};

  return (
    <div className="topBannerDiv">
      {isMenuCollapsed ? (
        <img
          alt="enterot"
          className="companyLogo"
          title={'Enterot https://enterot.com/'}
          src={ImageLogo}
          onClick={() => {
            window.open('https://enterot.com/', '_blank');
          }}
        />
      ) : (
        ''
      )}
      <div style={{ flex: 1 }} />
      <Popover
        isOpen={logoutPopover}
        positions={['bottom', 'right']}
        containerStyle={{ Index: '7' }}
        onClickOutside={(e) => {
          e.preventDefault();
          setLogoutPopover(false);
        }}
        content={() => (
          <div className="logoutPopoverContent">
            <img className="popoverUserImage" src={image} alt="emp" />
            <div className="popoverEmployeeName">{responseData.username}</div>
            <div className="popoverEmployeeId">{responseData.role}</div>
            <button
              className="logoutButton"
              onClick={() => {
                setLogoutPopover(false);
                logout();
                Navigate('/');
              }}
            >
              Log Out
            </button>
          </div>
        )}
      >
        <div
          className="logOutGroup"
          onClick={() => setLogoutPopover(!logoutPopover)}
        >
          <img className="topBannerUserImage" src={image} alt="User" />
        </div>
      </Popover>
    </div>
  );
};

export default Banner;
