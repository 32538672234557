const costCenterFormFields = {
	sections: [
	  [
		{
		  label: 'Sales Person Name',
		  colSpan: '8',
		  isRequired: true,
		  id: 'cost_center_name',
		  type: 'text',
		},
		{
		  label: 'Phone Number',
		  colSpan: '8',
		  isRequired: true,
		  id: 'phone',
		  type: 'text',
		},
		{
		  label: 'Emergency Contact No',
		  colSpan: '8',
		  isRequired: true,
		  id: 'emergency_contact_no',
		  type: 'text',
		},
	  ],
	  [
		{
		  label: 'Address 1',
		  colSpan: '9',
		  isRequired: false,
		  id: 'address_1',
		  type: 'text',
		},
		{
		  label: 'Address 2',
		  colSpan: '9',
		  isRequired: false,
		  id: 'address_2',
		  type: 'text',
		},
		{
		  label: 'E-Mail',
		  colSpan: '6',
		  isRequired: false,
		  id: 'email',
		  type: 'text',
		},
	  ],
	  [
		{
		  label: 'PAN Card No.',
		  colSpan: '9',
		  isRequired: false,
		  id: 'pan_card_no',
		  type: 'text',
		},
		{
		  label: 'Prefix No',
		  colSpan: '9',
		  isRequired: false,
		  id: 'prefix_no',
		  type: 'text',
		},
		{
		  label: 'Active',
		  colSpan: '6',
		  isRequired: false,
		  id: 'active',
		  type: 'checkbox',
		},
	  ],
	  [
		{
		  label: 'Bank Details',
		  colSpan: '12',
		  isRequired: false,
		  id: 'bank_details_1',
		  type: 'text',
		},
		{
		  label: 'Branch Details',
		  colSpan: '12',
		  isRequired: false,
		  id: 'bank_details_1_branch_details',
		  type: 'text',
		},
		{
		  label: 'Account Number',
		  colSpan: '12',
		  isRequired: false,
		  id: 'account_details_1',
		  type: 'text',
		},
		{
		  label: 'IFSC Code',
		  colSpan: '12',
		  isRequired: false,
		  id: 'bank_details_1_ifsc_code',
		  type: 'text',
		},
	  ],
	],
  };
  
  export default costCenterFormFields;
  